import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import $ from "strings/talent";
import { SPECIALTY_OPTIONS } from "../onboard/constants";
import {
  ExperienceIcon,
  LocationIcon,
  SpecialtiesIcon
} from "pages/talent/onboard/icons";
import { EditBtn } from "components/Buttons";
import userImg from "assets/user.svg";
import ProfilePhotoForm from "./ProfilePhotoForm";
import { Button } from "../components";
import WorkWithForm from "./WorkWithForm";
import { getUserId } from "../../../utils/localStorageService";
import { getUrlSuffix } from "../../../utils/localStorageService";

/***
 * Helper function to get a displayable location string from a user contact info object.
 */
const getLocation = (userContactInfo) => {
  if (!userContactInfo) return null;

  const { city, state_code, state, country } = userContactInfo;
  if (city && state_code) {
    return `${city}, ${state_code}`;
  } else if (city && country) {
    return `${city}, ${country}`;
  } else if (city) {
    return city;
  } else if (country) {
    return country;
  } else if (state) {
    return state;
  } else if (state_code) {
    return state_code;
  }
};

/**
 * Helper function to get an array of displayable specialty labels from a
 * string of specialty values.
 */
const getSpecialtyLabelsFromString = (specialties) => {
  return (
    specialties?.split("|").map((s) => {
      return SPECIALTY_OPTIONS.find((o) => o.value === s)?.label;
    }) ?? []
  );
};

/**
 * Component that displays the availability status of a talent.
 */
const AvailabilityIndicator = ({ onClick, availability_capacity }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "cursor-pointer whitespace-nowrap rounded-lg px-2 py-1 text-sm",
        availability_capacity > 0
          ? "bg-muted-green text-medium-green"
          : "bg-red-orange text-red-darker"
      )}
    >
      {availability_capacity > 0
        ? $.currently_available
        : $.limited_availability}
    </div>
  );
};

/**
 * Generic pill component that wraps minimal text.
 */
const Pill = ({ children }) => {
  return (
    <div className="h-fit whitespace-nowrap rounded-lg bg-lightest-grey px-2.5 py-1.5 text-sm text-midnight">
      {children}
    </div>
  );
};

/**
 * Work with ... button. Hidden on smaller screens because it is displayed
 * in the upper navbar
 */
const WorkWithButton = ({ isViewingSelf, talentDisplayName, setModal }) => {
  return (
    <div className="hidden md:flex">
      <Button
        noMinW={true}
        onClick={() => {
          if (isViewingSelf) return;
          setModal("work-with");
        }}
      >
        {$.work_with_btn} {talentDisplayName}
      </Button>
    </div>
  );
};

/**
 * Header component for the talent profile page. Displays the users name, profile picture, and other
 * sumary information.
 */
const Header = ({
  talentData,
  setTalentData,
  isPublicProfile,
  setEditProfilePhoto
}) => {
  const [modal, setModal] = useState("");
  const loggedInUserId = getUserId();
  const history = useHistory();
  const userSuffix = getUrlSuffix();
  const { user, specialties, years_of_experience, availability_capacity } =
    talentData;

  const specialtyLabels = getSpecialtyLabelsFromString(specialties);
  const location = getLocation(user.user_contact_info);

  useEffect(() => {
    if (setEditProfilePhoto) {
      setEditProfilePhoto(() => () => setModal("photo"));
    }
  }, [setEditProfilePhoto]);

  return (
    <div className="relative flex rounded-extra-large bg-white p-4 text-midnight">
      <div className="absolute left-0 top-0 h-24 w-full overflow-hidden rounded-t-extra-large bg-alice-blue" />
      <div className="relative flex w-full flex-col gap-2 lg:flex-row lg:items-start lg:gap-4">
        <div className="mt-2 h-25 w-25 flex-shrink-0 items-end rounded-xl lg:mt-6 lg:h-50 lg:w-50">
          <img
            className="rounded-lg"
            src={user.profile_photo || userImg}
            alt="profile"
          />
          {!isPublicProfile && (
            <EditBtn
              className="absolute -right-1 -top-1 z-10 rounded-xl"
              onClick={() => setModal("photo")}
            />
          )}
        </div>
        <div className="flex flex-grow flex-col lg:pt-24">
          <div className="flex w-full flex-col gap-y-1.5">
            <div className="mb-1 flex w-full flex-col items-start gap-2 md:flex-row md:items-center md:gap-4">
              <div className="flex items-baseline gap-2 md:items-center">
                <h1 className="text-1.5xl font-bold">
                  {user.preferred_name || user.first_name} {user.last_name}
                </h1>
                {user.pronouns && (
                  <p className="text-normal">{`(${user.pronouns})`}</p>
                )}
              </div>
              <AvailabilityIndicator
                onClick={() => {
                  if (!isPublicProfile) {
                    history.push(
                      `/talent/${userSuffix}/settings?tab=profile_settings:availability`
                    );
                  }
                }}
                availability_capacity={availability_capacity}
              />
            </div>
            <div className="flex-1 gap-2">
              {!!location && (
                <div className="flex gap-2 py-1.5 text-sm">
                  <LocationIcon />
                  <div className="flex gap-x-1.5">
                    <div>{$.location_header_label}</div>
                    <div className="text-kasmir">{location}</div>
                  </div>
                </div>
              )}
              <div className="flex w-full items-start gap-2 py-1.5 text-sm">
                <ExperienceIcon />
                <div className="flex w-full gap-x-1.5">
                  <div>{$.experience_header_label}</div>
                  <div className="text-kasmir">
                    {years_of_experience}{" "}
                    {years_of_experience === 1 ? "year" : "years"}
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-grow flex-wrap items-center gap-1.5 gap-y-2 text-sm">
                <div className="flex flex-row gap-2 py-1.5">
                  <SpecialtiesIcon />
                  <div>{$.specialties_header_label}</div>
                </div>
                {specialtyLabels.map((s) => (
                  <Pill key={s}>{s}</Pill>
                ))}
                <div className="h-fit flex-grow justify-items-end">
                  {isPublicProfile ? (
                    <WorkWithButton
                      isViewingSelf={loggedInUserId === user.id}
                      talentDisplayName={user.preferred_name || user.first_name}
                      setModal={setModal}
                    />
                  ) : (
                    <Button
                      noMinW={true}
                      onClick={() => {
                        // open {current_url}/?public=true in a new tab
                        window.open(
                          `${window.location.href}?public=true`,
                          "_blank"
                        );
                      }}
                    >
                      {$.see_public_view_btn}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal === "photo" && (
        <ProfilePhotoForm
          onClose={() => setModal("")}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      )}
      {modal === "work-with" && (
        <WorkWithForm
          onClose={() => setModal("")}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      )}
    </div>
  );
};

export default Header;
