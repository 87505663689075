import React, {useEffect, useState} from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";
import AboutMeForm from "./AboutMeForm";
import { urlify } from "utils/str";
import placeholderImg from "../assets/about_me_placeholder.png";

const AboutMe = ({ talentData, setTalentData, isPublicProfile, setOpenAboutMe }) => {
  const [modal, setModal] = useState();
  const { about_me } = talentData;

  useEffect(() => {
    if (setOpenAboutMe) {
      setOpenAboutMe(() => () => setModal('aboutMe'));
    }
  }, [setOpenAboutMe]);

  return (
    <>
      { isPublicProfile && !about_me ? <></> :
      <div className="bg-white rounded-extra-large p-4 text-midnight gap-y-4 flex flex-col">
        <div className="flex">
          <div className="text-lg font-bold">{$.about_me_title}</div>
          <div className="ml-auto" hidden={isPublicProfile}>
            <EditBtn className="w-full" onClick={() => setModal('aboutMe')} />
          </div>
        </div>
        <div className="text-sm">
          {about_me ?
            <div className="about-me" dangerouslySetInnerHTML={{ __html: urlify(about_me) }} /> :
            !isPublicProfile &&
            <div className="flex flex-col">
              <div className="h-56 flex justify-center">
                <img src={placeholderImg} className="object-contain" alt="Add a project" />
              </div>
              <div className="mx-auto w-2/3 text-kasmir text-center pb-2">{$.about_me_empty}</div>
            </div>
          }
        </div>
        {modal === "aboutMe" &&
          <AboutMeForm
            onClose={() => setModal()}
            talentData={talentData}
            setTalentData={setTalentData}
          />
        }
      </div>
      }
    </>
  )
};

export default AboutMe;
