import React, { useMemo } from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";
import Pill from "../components/Pill";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";
import {useHistory} from "react-router-dom";
import {getUrlSuffix} from "../../../utils/localStorageService";
import placeholderImg from "../assets/skills_placeholder.png";


const Skills = ({ talentData, isPublicProfile }) => {
  const { qualifications } = talentData;
  const userSuffix = getUrlSuffix();

  const history = useHistory();

  const skills = useMemo(() => {
    if (!qualifications) return;
    return qualifications.filter(
      q => q.qualification?.type.toLowerCase() === 'skill' && q.display_on_talent_profile
    ).map(s => s.qualification);
  }, [qualifications, isPublicProfile]);

  return (
    <>
    { isPublicProfile && !skills?.length ? <></> :
    <div className="bg-white rounded-extra-large p-4 flex flex-col gap-4">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold text-midnight">Skills</div>
        <Pill value={skills?.length || 0} />
        {!isPublicProfile && <div className="ml-auto">
          <EditBtn
            className="w-full"
            onClick={() => {
              const userSuffix = getUrlSuffix();
              history.push(`/talent/${userSuffix}/expertise?tab=skills`);
            }}
          />
        </div>}
      </div>
      <div className="text-sm">
        {skills.length ? <QualificationsDisplay qualifications={skills} large /> :
          !isPublicProfile &&
          <div className="flex flex-col">
            <div className="h-56 flex justify-center">
              <img src={placeholderImg} className="object-contain" alt="Add a project" />
            </div>
            <div
              className="mx-auto w-3/5 text-kasmir text-center pb-2"
              dangerouslySetInnerHTML={{
                __html: $.skills_placeholder.replace("#", `<a class="text-link underline cursor-pointer" href="/talent/${userSuffix}/expertise?tab=skills">My Expertise tab</a>`)
              }}
            />
          </div>
        }
      </div>
    </div>
    }
    </>
  )
};

export default Skills;
