import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {GoogleLogin} from '@react-oauth/google';
import {oauth} from "utils/adminApi";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "../../components/Form";
import {Link, useHistory} from "react-router-dom";
import {Button, PointedTooltip} from "./components";
import {saveLoginToken} from "../../utils/localStorageService";
import spheres1 from "../../assets/registration_spheres_1.png";
import spheres2 from "../../assets/registration_spheres_2.png";
import logoSection from "../../assets/logo_section.png";
import registrationFace from "../../assets/registration_face.png";


const Register = ({ setLogged }) => {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    document.title = "Register";
    const userId = new URLSearchParams(window.location.search).get('userId');
    const email = new URLSearchParams(window.location.search).get('email');
    if (userId) {
      setUserId(userId);
    }
    if (email) {
      setEmail(email);
    }
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const responseGoogle = async (response) => {
    try {
      const authResponse = await oauth(response.credential);
      const talent = authResponse.talent;
      if (!talent) {
        alert.error(<AlertError message="Talent not found." />);
        return;
      }
      saveLoginToken(authResponse);
      setLogged(true);
      window.location.href = `/talent/onboard?user=${authResponse.talent.user.id}`;
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  const failedGoogle = (response) => {
    console.error(response);
    const e = { message: response.error };
    alert.error(<AlertError error={e} message={$.google_auth_error} />);
  };

  const handleRegister = async () => {
    if (!email || !userId) {
      alert.error(<AlertError error={{message: 'Email and userId are required'}} message={$.auth_error} />);
      return;
    }
    // go to /talent/register-password with email and userId in query params
    history.push(`/talent/register-password?email=${email}&userId=${userId}`);
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center px-24 py-12 text-dark-navy">
      <div className="rounded-2xl bg-white relative w-full h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col justify-center py-10 px-10 gap-y-7.5"
          >
            <div>
              <h1 className="font-semibold text-2xl text-center">
                Join the Right Side Up Collective!
              </h1>
            </div>
            <div className="flex flex-col justify-center w-full gap-y-5">
              <div className="justify-center flex">
                <GoogleLogin
                  className="w-full"
                  onSuccess={responseGoogle}
                  onError={failedGoogle}
                  context="signin"
                  size="large"
                />
              </div>
              <div className="flex items-center w-full">
                <hr className="flex-auto w-auto border-t text-grey"/>
                <span className="flex-none mx-4 text-grey text-sm font-normal">OR</span>
                <hr className="flex-auto w-auto border-t text-grey"/>
              </div>
            </div>
            <div className="flex flex-col gap-y-5">
              <div className="flex flex-col gap-y-2">
                <label
                  className="text-sm text-left w-full"
                >
                  Email
                </label>
                <div className="w-full">
                  <PointedTooltip
                    hidden={!showTooltip}
                    msg="Contact support@rightsideup.com to update your email."
                    pointed={false}
                    translate="translate-x-16"
                    className="border-none shadow-md w-70"
                  >
                    <Input
                      disabled
                      placeholder={email}
                      fontSize="text-sm"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    />
                  </PointedTooltip>
                </div>
              </div>
              <p className="text-sm text-left w-full text-kasmir">
                By continuing, you agree to Right Side Up's {" "}
                <Link to="/terms" target="_blank" className="underline text-electric-indigo hover:text-electric-indigo-lighter">Terms and Conditions</Link>
                {" "}and{" "}
                <Link to="/privacy" target="_blank" className="underline text-electric-indigo hover:text-electric-indigo-lighter">{$.privacy_link}</Link>.
              </p>
              <Button
                className="w-full"
                onClick={handleRegister}
                large
              >
                Continue
              </Button>
              <p className="text-sm text-center w-full text-kasmir">
                Already on Right Side Up? {" "}
                <Link
                  to="/login"
                  className="underline text-electric-indigo hover:text-electric-indigo-lighter"
                >Log in</Link>
              </p>
            </div>
          </div>
          <div
            className="w-1/2 bg-dark-navy rounded-2xl text-white py-10 px-8 relative">
            <img className="absolute w-20 top-0 right-0" src={spheres1}
                 alt="rsu regi art 1"/>
            <img className="absolute w-20 bottom-0 left-0" src={spheres2}
                 alt="rsu regi art 1"/>
            <h1 className="text-3.5xl font-bold pr-20">
              “Out on your own” never felt so supported
            </h1>
            <p className="text-normal mt-1">
              Going out on your own can be scary. Don't worry - we're just one
              desk over.
            </p>
            <div
              className="my-14 pl-4 pr-6 py-4 bg-midnight-semidark rounded-xl text-normal">
              <p>“Right Side Up has been an incredibly valuable resource and partner.
                They’ve connected me with some of my all time favorite clients and supported
                my growth as a full time consultant.”</p>
              <div className="mt-6 flex gap-x-2.5">
                <div className="w-12">
                  <img className="rounded-full" src={registrationFace} alt="example user"/>
                </div>
                <div className="text-sm flex">
                  <div className="my-auto">
                    <p>Alley</p>
                    <p>Performance Marketing</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <p className="text-sm text-caded">
                If you've got the marketing chops, we probably got someone you
                should meet:
              </p>
              <img className="z-top" src={logoSection} alt="logo-section"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
