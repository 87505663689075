import React, {useEffect} from "react";
import {admin_login as $} from "strings";
import $$ from "strings/talent";
import {GoogleLogin} from '@react-oauth/google';
import {oauth} from "utils/adminApi";
import {loginPassword} from "utils/api";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "components/Form";
import {saveLoginToken} from "../../utils/localStorageService";
import {useHistory} from "react-router-dom";
import {Button, FormErrorMessage} from "./components";
import loginArt from "../../assets/login_art.png";
import * as yup from "yup";
import {Form, Formik} from "formik";


const Login = ({ setLogged, setAdminLogged }) => {
  useEffect(() => {
    document.title = "Login";
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleLoginResponse = (response) => {
    saveLoginToken(response);
    if (response.user) {
      setAdminLogged(true);
      window.location.href = `/admin`;
    } else if (response.talent) {
      setLogged(true);
      if (!response.talent.skills_survey_submitted_at) {
        window.location.href = `/talent/onboard?user=${response.talent.user.id}`;
      } else {
        window.location.href = `/talent/${response.talent.url_suffix}`;
      }
    }
  };

  const handleLoginError = (e) => {
    alert.error(<AlertError message={$$.invalid_credentials_copy} />);
  };

  const handleGoogleLogin = async (response) => {
    await oauth(response.credential)
      .then(handleLoginResponse)
      .catch(handleLoginError);
  };

  const handleGoogleError = (response) => {
    alert.error(<AlertError message={$.google_auth_error} />);
  };

  const initialValues = {
    email: '',
    password: ''
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("This field is required"),
    password: yup.string().required("This field is required")
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async e => {
          const { name, value } = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, !!value);
          if (errors[name]) {
            setFieldError(name, null)
          }
        };

        const handlePasswordLogin = async () => {
          const errors = await validateForm();
          if (Object.keys(errors).length) {
            return;
          }
          loginPassword(values)
            .then(handleLoginResponse)
            .catch(handleLoginError);
        };

        return (
          <Form className="form h-full">
            <div className="font-lato bg-alice-blue w-screen h-screen flex items-center justify-center px-24 py-12 text-dark-navy">
              <div className="rounded-2xl bg-white relative w-300 h-full ">
                <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
                  <img
                    className="max-w-full max-h-full"
                    src={logoSm}
                    alt="Right Side Up"
                  />
                </div>
                <div className="max-w-full h-full flex">
                  <div
                    className="w-1/2 h-full flex flex-col items-center justify-center px-10 gap-y-5"
                  >
                    <h1
                      className="font-semibold text-3xl mb-2.5">
                      Welcome back to Right Side Up!
                    </h1>
                    <div className="flex justify-center w-full">
                      <GoogleLogin
                        className="w-full"
                        onSuccess={handleGoogleLogin}
                        onError={handleGoogleError}
                        context="signin"
                        size="large"
                      />
                    </div>
                    <div className="flex items-center w-full mx-5">
                      <hr className="flex-auto w-auto border-t text-gray-300"/>
                      <span
                        className="flex-none mx-4 text-gray-300 text-sm font-normal">OR</span>
                      <hr className="flex-auto w-auto border-t text-gray-300"/>
                    </div>
                    <div className="w-full -mb-3">
                      <label
                        className="text-sm text-left w-full">
                        Email
                      </label>
                      <Input
                        type="email"
                        fontSize="text-sm"
                        value={values.email}
                        onChange={e => onChange({target: {name: 'email', value: e.target.value}})}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            handlePasswordLogin().then();
                          }
                        }}
                      />
                      <FormErrorMessage error={errors.email} />
                    </div>
                    <div className="w-full ">
                      <label
                        className="text-sm text-left w-full">
                        Password
                      </label>
                      <Input
                        type="password"
                        fontSize="text-sm"
                        value={values.password}
                        onChange={e => onChange({target: {name: 'password', value: e.target.value}})}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            handlePasswordLogin().then();
                          }
                        }}
                      />
                      <div className="mt-2.5 flex flex-row">
                        <FormErrorMessage error={errors.password} />
                        <p className="text-sm text-right w-full text-kasmir">
                          <button
                            type="button"
                            onClick={() => history.push('/talent/reset-password')}
                            className="underline text-electric-indigo hover:text-electric-indigo-lighter"
                          >Forgot your password?</button>
                        </p>
                      </div>
                    </div>
                    <Button
                      className="w-full"
                      onClick={handlePasswordLogin}
                      large
                    >Log in</Button>
                    <p className="text-sm text-center w-full text-kasmir">
                      New to RightSideUp? {" "}
                      <button
                        type="button"
                        onClick={() => {
                          window.open('https://www.rightsideup.com/for-talent', '_blank');
                        }}
                        className="underline font-bold text-electric-indigo hover:text-electric-indigo-lighter"
                      >Contact our Talent Team!</button>
                    </p>
                  </div>
                  <div
                    className="w-1/2 h-full bg-lightest-grey rounded-r-2xl text-center flex flex-col justify-between"
                  >
                    <div className="px-8 h-1/4 mt-20 gap-y-3 flex flex-col">
                      <h1 className="text-2.5xl font-bold">
                        Be your own boss, boss
                      </h1>
                      <p className="text-sm">
                        Deliver excellent work that thrills clients by taking on
                        projects
                        that light you up. Whether you want to moonlight or freelance
                        full
                        time, you dictate who you work with, how many hours you work,
                        and how much you get.
                      </p>
                    </div>
                    <div className="h-full flex items-end">
                      <img className="" src={loginArt} alt="rsu art 1"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}}
    </Formik>
  );
};

export default Login;
