import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  getAccessToken,
  getRefreshToken,
  setToken,
  clearLoginToken,
} from "./localStorageService";
import history from "./history";

let setLogged;

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

ax.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Sentry.captureException(error);
    Promise.reject(error);
  }
);

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      (originalRequest._retry ||
        originalRequest.url.includes("auth/token/refresh/"))
    ) {
      clearLoginToken();
      setLogged(false);
      history.push("/login");
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();

      const res = await ax.post(
        "auth/token/refresh/",
        { refresh: refreshToken },
        { headers: { Authorization: "Basic Og==" } }
      );

      if (res.status === 200) {
        setToken(res.data);

        return ax(originalRequest);
      }
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export const configureLoggedSetter = (setter) => {
  setLogged = setter;
};

export const login = async (params) => {
  return (
    await ax.post("auth/token/", params, {
      headers: { Authorization: "Basic Og==" },
    })
  ).data;
};

export const getFlags = async () => {
  return (await ax.get(`flags/`)).data;
};

export const oauth = async (token) => {
  return (await ax.post("oauth/", { token })).data;
};

export const oauthSignup = async (token) => {
  return (await ax.post("oauth/register/", { token })).data;
};

export const registerPassword = async (data) => {
  return (await ax.post("auth/register/", data)).data;
};

export const loginPassword = async (data) => {
  return (await ax.post("auth/login/", data)).data;
};

export const resetPassword = async (data) => {
  return (await ax.post("auth/reset-password/", data)).data;
};

export const resetPasswordConfirm = async (userId, token, data) => {
  return (await ax.post(`auth/reset-password-confirm/${userId}/${token}/`, data)).data;
};

export const postUser = async (data) => {
  return (await ax.post(`accounts/`, data)).data;
};

export const getAccount = async (userId) => {
  return (await ax.get(`accounts/${userId}/`)).data;
};

export const patchAccount = async (userId, data) => {
  return (await ax.patch(`accounts/${userId}/`, data)).data;
};

export const getQualifications = async (params) => {
  return (await ax.get("/qualifications/", { params })).data;
};

export const postReferral = async (data) => {
  return (await ax.post(`referals/`, data)).data;
};

export const getTalentLinks = async (user__user_id) => {
  return (await ax.get(`accounts/talent_links/`, { params: { user__user_id } }))
    .data;
};

export const patchUser = async (userId, data) => {
  return (await ax.patch(`accounts/user/${userId}/`, data)).data;
};

export const postTalentLink = async (data) => {
  return (await ax.post(`accounts/talent_links/`, data)).data;
};

export const patchTalentLink = async (id, data) => {
  return (await ax.patch(`accounts/talent_links/${id}/`, data)).data;
};

export const deleteTalentLink = async (id) => {
  return (await ax.delete(`accounts/talent_links/${id}/`)).data;
};

export const deleteTalentProject = async (id) => {
  return (await ax.delete(`accounts/talent_projects/${id}/`)).data;
};

export const patchTalentProject = async (id, data) => {
  return (await ax.patch(`accounts/talent_projects/${id}/`, data)).data;
};

export const postTalentProject = async (data) => {
  return (await ax.post(`accounts/talent_projects/`, data)).data;
};

export const getTalentProjects = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_projects/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const getTalentQualifications = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_qualifications/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const postTalentQualification = async (data) => {
  return (await ax.post(`accounts/talent_qualifications/`, data)).data;
};

export const patchTalentQualification = async (id, data) => {
  return (await ax.patch(`accounts/talent_qualifications/${id}/`, data)).data;
};

export const deleteTalentQualification = async (id) => {
  return (await ax.delete(`accounts/talent_qualifications/${id}/`)).data;
};

export const validateUser = async (token) => {
  return await ax.get(`accounts/isUser/${token}`);
};

export const updateTalentProfile = async (data) => {
  return (await ax.put(`accounts/onboard/`, data)).data;
};

// TALENT TECH
// private data (e.g. qualifications) only visible to that user, when authenticated
export const getTalentData = async (id) => {
  return (await ax.get(`talent/talent/${id}/`)).data;
};

export const getTalentDataFromUrlSuffix = async (suffix) => {
  return (await ax.get(`talent/talent/url_suffix/${suffix}/`)).data;
};

export const patchTalentTalent = async (id, data) => {
  return (await ax.patch(`talent/talent/${id}/`, data)).data;
};

export const patchTalentUser = async (id, data) => {
  return (await ax.patch(`talent/user/${id}/`, data)).data;
};

export const getTalentUserLinks = async () => {
  return (await ax.get(`talent/user_link/`)).data;
};

export const getTalentUserLinksPreview = async () => {
  return (await ax.get(`talent/user_link_preview/`)).data;
};

export const patchTalentUserLink = async (id, data) => {
  return (await ax.patch(`talent/user_link/${id}/`, data)).data;
};

export const postTalentUserLink = async (data) => {
  return (await ax.post(`talent/user_link/`, data)).data;
};

export const deleteTalentUserLink = async (id) => {
  return (await ax.delete(`talent/user_link/${id}/`)).data;
};

export const patchTalentUserContactInfo = async (id, data) => {
  return (await ax.patch(`talent/user_contact_info/${id}/`, data)).data;
};

export const postTalentTalentQualification = async (data) => {
  return (await ax.post(`talent/talent_qualification/`, data)).data;
};

export const patchTalentTalentQualification = async (id, data) => {
  return (await ax.patch(`talent/talent_qualification/${id}/`, data)).data;
};

export const getTalentTalentQualifications = async ({ params }) => {
  return (await ax.get(`talent/talent_qualification/`, { params })).data;
};

export const deleteTalentTalentQualification = async (id) => {
  return (await ax.delete(`talent/talent_qualification/${id}`)).data;
};

export const getTalentRSUProjects = async () => {
  return (await ax.get(`talent/rsu_project/`)).data;
};

export const getTalentTalentCreatedProjects = async () => {
  return (await ax.get(`talent/talent_created_project/`)).data;
};

export const postTalentTalentCreatedProject = async (data) => {
  return (await ax.post(`talent/talent_created_project/`, data)).data;
};

export const patchTalentTalentCreatedProject = async (id, data) => {
  return (await ax.patch(`talent/talent_created_project/${id}/`, data)).data;
};

export const deleteTalentTalentCreatedProject = async (id) => {
  return (await ax.delete(`talent/talent_created_project/${id}/`)).data;
};

export const getTalentTalentCreatedCompanies = async (params) => {
  return (await ax.get(`talent/talent_created_company/`, { params })).data;
};

export const postTalentTalentCreatedCompany = async (data) => {
  return (await ax.post(`talent/talent_created_company/`, data)).data;
};

export const postTalentTalentCreatedCompanyFromURL = async (data) => {
  // this is effectively a get_or_create
  return (await ax.post(`talent/talent_created_company/import_from_url/`, data)).data;
};

export const postTalentAttachedFile = async (data) => {
  return (await ax.post(`talent/attached_file/`, data)).data;
};

export const patchTalentAttachedFile = async (id, data) => {
  return (await ax.patch(`talent/attached_file/${id}/`, data)).data;
};

export const deleteTalentAttachedFile = async (id) => {
  return (await ax.delete(`talent/attached_file/${id}/`)).data;
};

export const postTalentProfilePhoto = async (data) => {
  return (await ax.post(`talent/profile_photo/`, data)).data;
};

export const deleteTalentProfilePhoto = async (data) => {
  return (await ax.delete(`talent/profile_photo/`, data)).data;
};

export const postTalentLead = async (data) => {
  return (await ax.post(`talent/lead/`, data)).data;
};
