import React from "react";
import logoSm from "assets/logo_sm.svg";

/**
 * Renders the brand logo, it uses a small and normal size ones.
 */
const Logo = ({className='w-7.5', title}) => (
  <div className={`left-0 top-0 h-full inline-flex items-center ${className}`}>
    <img className="max-w-full max-h-full" src={logoSm} alt="Right Side Up" title={title} />
  </div>
);

export default Logo;
