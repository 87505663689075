import React, { useEffect, useState } from "react";
import $ from "strings/talent";
import EditLinksForm from "./EditLinksForm";
import { EditBtn } from "components/Buttons";
import LinkButton from "../components/LinkButton";
import placeholderImg from "../assets/links_placeholder.png";

const AdditionalLinks = ({ talentData, setTalentData, isPublicProfile }) => {
  const [modal, setModal] = useState();
  const [talentLinks, setTalentLinks] = useState()

  useEffect(() => {
    if (!talentData) return;
    const links = talentData?.user?.user_links.filter(l => l.name !== 'Scheduling Link');
    setTalentLinks(links);
  }, [talentData]);

  return (
    <>
    { isPublicProfile && !talentLinks?.length ? <></> :
    <div className="bg-white rounded-extra-large p-4 gap-y-3.5 flex flex-col">
      <div className="flex items-center">
        <div className="text-lg font-bold">Additional Links</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn onClick={() => setModal('links')}/>
        </div>}
      </div>
      <div className="text-sm">
        {talentLinks?.length ?
          <div className="text-sm flex gap-x-3">
            {talentLinks?.map(link =>
              <LinkButton key={link.id} link={link} />
            )}
          </div> :
          !isPublicProfile &&
          <div className="flex flex-col">
            <div className="h-20 flex justify-center">
              <img src={placeholderImg} className="object-contain" alt="Add a project" />
            </div>
            <div className="mx-auto w-4/5 text-kasmir text-center pb-2">
              {$.links_placeholder}
            </div>
          </div>
        }
      </div>

      {modal === "links" &&
        <EditLinksForm
          onClose={() => setModal()}
          talentData={talentData}
          setTalentData={setTalentData}
        />
      }
    </div>
    }
    </>
  )
};

export default AdditionalLinks;
