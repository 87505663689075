import React, { useEffect, useState } from "react";
import $ from "strings/talent";
import { NavLink, useHistory } from "react-router-dom";
import { withHttp } from "utils/str";
import Logo from "components/Logo";
import userImg from "assets/user.svg";

import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import {
  clearLoginToken,
  getAccessToken,
  getUrlSuffix,
} from "../../../utils/localStorageService";
import { ArrowRight, Menu } from "react-feather";
import { Button } from "../components";
import WorkWithForm from "../Profile/WorkWithForm";

const RESOURCES_URL =
  "https://learn.rightsideup.com/contractor-resources/?utm_medium=product&utm_source=direct&utm_campaign=talent_profile&utm_content=talent&utm_term=talent_profile";
const TIMESHEETS_URL = "https://app.clockify.me/tracker";

const getNavTextColor = (path) => {
  return window.location.pathname.includes(path)
    ? "text-electric-indigo"
    : "text-nav-blue";
};

export const NavBar = ({ talentData, isPublicProfile }) => {
  const [logged, setLogged] = useState(!!getAccessToken());
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [isShowingWorkWithModal, setShowingWorkWithModal] = useState(false);
  const history = useHistory();
  const {
    user: { first_name, last_name, preferred_name, profile_photo },
  } = talentData;

  const userSuffix = getUrlSuffix();

  const showNavigationLinks = !isPublicProfile && logged;

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (isUserMenuOpen) {
        setUserMenuOpen(false);
      }
    });
  });

  return (
    <div className="bg-white flex h-13 mx-4 mt-4 px-3.5 py-2.5 rounded-large items-center select-none justify-between">
      <div
        className="flex items-center mr-8 h-full cursor-pointer"
        onClick={() => history.push("/talent")}
      >
        <Logo />
      </div>

      {/* Public Profile.*/}
      {isPublicProfile && (
        <>
          <div className="flex md:hidden">
            <Button
              noMinW={true}
              onClick={() => {
                setShowingWorkWithModal(true);
              }}
            >
              {$.work_with_btn} {preferred_name || first_name}
            </Button>
          </div>
        </>
      )}

      {/* Nav links */}
      {showNavigationLinks && (
        <div className="flex justify-between h-full w-full items-center ">
          <div className="flex-1 min-w-0 items-center">
            <div className="text-base text-nav-blue font-bold flex-1 flex gap-4 mx-0 my-auto">
              <NavLink
                to={`/talent/${userSuffix}/profile`}
                className={getNavTextColor("profile")}
              >
                {$.profile_navlink}
              </NavLink>
              <NavLink
                to={`/talent/${userSuffix}/expertise`}
                className={getNavTextColor("expertise")}
              >
                {$.expertise_navlink}
              </NavLink>
              <NavLink
                to={{ pathname: withHttp(RESOURCES_URL) }}
                target="_blank"
              >
                {$.resource_navlink}
              </NavLink>
              <NavLink
                to={{ pathname: withHttp(TIMESHEETS_URL) }}
                target="_blank"
              >
                {$.timesheets_navlink}
              </NavLink>
            </div>
          </div>
          <div
            className="ml-auto h-full flex items-center cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setUserMenuOpen(!isUserMenuOpen);
            }}
          >
            <img
              src={profile_photo || userImg}
              className="rounded-full h-full w-auto mr-1.25"
              alt={`${first_name} ${last_name}`}
            />
            {isUserMenuOpen ? <UilUp size="20" /> : <UilDown size="20" />}
            {isUserMenuOpen ? (
              <div className="flex flex-col absolute z-10 top-14 right-8 p-2 bg-white border border-lightest-grey rounded-md text-sm text-nav-blue cursor-pointer">
                <NavLink
                  className="p-1 rounded-sm hover:bg-link-water"
                  to={`/talent/${userSuffix}/settings`}
                >
                  {$.settings_navlink}
                </NavLink>
                <a
                  className="p-1 rounded-sm hover:bg-link-water"
                  href="https://rightsideup.slite.page/p/8oZZhIIjSmmEoH/Talent-Knowledge-Portal"
                  target="_blank"
                  rel="noreferrer"
                >
                  {$.support_navlink}
                </a>
                <NavLink
                  className="p-1 rounded-sm hover:bg-link-water"
                  onClick={() => {
                    clearLoginToken();
                    window.location.href = "/login"; // requires full page reload
                  }}
                  to={`/login`}
                >
                  {$.logout_navlink}
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {isShowingWorkWithModal && (
        <WorkWithForm
          onClose={() => setShowingWorkWithModal(false)}
          talentData={talentData}
        />
      )}
    </div>
  );
};

export default NavBar;
