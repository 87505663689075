import React from 'react';

const isImage = (url) => {
  return !!url && (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
};


export const LinkPreview = ({ name, url, ...props }) => {
  return (
    <div
      {...props}
      className={`flex mt-1 cursor-default select-none ${props.className}`}
    >
      <div className="h-17.5 w-30 flex rounded-lg">
        {isImage(url) ? (<img
          src={url}
          alt={name}
          className="w-full object-cover object-center rounded-lg"
        />) : (<div className="w-full bg-alice-blue flex items-center justify-center rounded-lg">
          <div className="text-midnight text-sm">No preview</div>
        </div>)}
      </div>
      <div className="h-17.5 mx-2.5 flex flex-col font-bold">
        <div className="my-auto">{name}</div>
      </div>
    </div>
  )
};
