import React, { useMemo, useState, useEffect } from "react";
import $ from "strings/talent";
import $$ from "strings/talent";
import { Label } from "components/Form";
import { B2DB } from "components/Typography"
import {
  RatingHelper,
  Qualification,
  Pill
} from "../components"
import ActionBar from "./ActionBar";
import { remove } from "lodash";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import {onCheckboxClick} from "./common";

const HIDDEN_INDUSTRY_IDS = [
  '57ddc7ac-da7e-4eee-946c-0b697e0819e8' // International Talent - not user facing
];

const Industries = ({
  rsuQualifications,
  talentIndustries,
  talentCompanyStages,
  onUpdate,
  isUpdating,
  setShowNavPrompt
})=> {
  const [open, setOpen] = useState('industry');
  const [talentIndustryValues, setTalentIndustryValues] = useState([...talentIndustries]);
  const [talentCompanyStageValues, setTalentCompanyStageValues] = useState([...talentCompanyStages]);
  const [displayActionBar, setDisplayActionBar] = useState(false);
  const [toUpdate, setToUpdate] = useState([]);
  const [toCreate, setToCreate] = useState([]);
  const [displayCount, setDisplayCount] = useState();

  const PromptUpdater = () => {
    useEffect(() => {
      const pageUrl = window.location.href;
      if (displayActionBar === true && pageUrl.includes("tab=industries")) {
        setShowNavPrompt(true);
      } else {
        setShowNavPrompt(false);
      }
    }, [displayActionBar])

    return null;
  }

  useEffect(() => {
    if (!talentIndustryValues || !talentCompanyStageValues) return;
    setDisplayCount(
      talentIndustryValues.filter(s => s.display_on_talent_profile === true).length +
      talentCompanyStageValues.filter(s => s.display_on_talent_profile === true).length
    );
  }, [talentIndustryValues, talentCompanyStageValues]);

  useEffect(() => {
    const pageUrl = window.location.href;
    if (pageUrl.includes('tab=industries:companyStage')) {
      setOpen('companyStage');
    } else if (pageUrl.includes('tab=industries:industry')) {
      setOpen('industry');
    }
  }, []);

  useEffect(() => {
    if (!toCreate || !toUpdate) setDisplayActionBar(false);
    toUpdate.length || toCreate.length ? setDisplayActionBar(true) : setDisplayActionBar(false)
  }, [toCreate, toUpdate]);

  const [ratedIndustries, RSUIndustries] = useMemo(() => {
    let talent = [];
    let rsu = [];

    const industries = rsuQualifications?.filter(q => HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 && q.type.toLowerCase() === 'industry');
    industries.forEach(s => {
      const isRated = talentIndustryValues.find(ts => ts.qualification_id === s.id);
      isRated ? talent.push({...s, talent_rating: isRated.talent_rating}) : rsu.push(s)
    });
    talent.sort((a, b) => b.talent_rating - a.talent_rating || a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    rsu.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return [talent, rsu];
  }, [rsuQualifications])

  const [ratedCompanyStages, RSUCompanyStages] = useMemo(() => {
    let talent = [];
    let rsu = [];

    const companyStages = rsuQualifications?.filter(q => q.type.toLowerCase() === 'company_stage');
    companyStages.forEach(s => {
      const isRated = talentCompanyStageValues.find(ts => ts.qualification_id === s.id);
      isRated ? talent.push({...s, talent_rating: isRated.talent_rating}) : rsu.push(s)
    });
    talent.sort((a, b) => b.talent_rating - a.talent_rating || a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    rsu.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return [talent, rsu];
  }, [rsuQualifications])

  // INFO: industries are qualifications
  const onIndustryRatingChange = (id, rating) => {
    let newToUpdate = [...toUpdate];
    let newToCreate = [...toCreate];
    let newTalentIndustryValues = [...talentIndustryValues];
    let checked = document.getElementById(`checkbox--${id}`)?.checked;
    const existingQualification = talentIndustries.find(industry => industry.qualification_id === id);

    if (existingQualification) {
      remove(newToUpdate, industry => industry.qualification_id === id);
      newToUpdate.push({
        qualification_id: id,
        talent_qualification_id: existingQualification.talent_qualification_id,
        talent_rating: rating,
        display_on_talent_profile: displayCount < 10 ? true : checked
      });
      setToUpdate([...newToUpdate]);
    } else {
      remove(newToCreate, industry => industry.qualification_id === id);
      if (rating !== 0) {
        newToCreate.push({
          qualification_id: id,
          talent_rating: rating,
          display_on_talent_profile: displayCount < 10 ? true : checked
        });
        setToCreate([...newToCreate]);
      }
    }
    const removed = remove(newTalentIndustryValues, skill => skill.qualification_id === id);
    newTalentIndustryValues.push({...removed[0], qualification_id: id, talent_rating: rating, display_on_talent_profile: checked});
    setTalentIndustryValues([...newTalentIndustryValues]);
  };

  const onCompanyStageRatingChange = (id, rating) => {
    let newToUpdate = [...toUpdate];
    let newToCreate = [...toCreate];
    let newTalentCompanyStageValues = talentCompanyStageValues;
    let checked = document.getElementById(`checkbox--${id}`)?.checked;
    const existingQualification = talentCompanyStages.find(industry => industry.qualification_id === id);

    if (existingQualification) {
      remove(newToUpdate, stage => stage.qualification_id === id);
      newToUpdate.push({
        qualification_id: id,
        talent_qualification_id: existingQualification.talent_qualification_id,
        talent_rating: rating,
        display_on_talent_profile: checked
      });
      setToUpdate([...newToUpdate]);
    } else {
      remove(newToCreate, stage => stage.qualification_id === id);
      if (rating !== 0) {
        newToCreate.push({
          qualification_id: id,
          talent_rating: rating,
          display_on_talent_profile: checked,
        });
        setToCreate([...newToCreate]);
      }
    }
    const removed = remove(newTalentCompanyStageValues, skill => skill.qualification_id === id);
    newTalentCompanyStageValues.push({...removed[0], qualification_id: id, talent_rating: rating, display_on_talent_profile: checked});
    setTalentCompanyStageValues([...newTalentCompanyStageValues]);
  };

  const onIndustryCheckboxClick = (id) => {
    return onCheckboxClick(id, talentIndustryValues, setTalentIndustryValues, toCreate, setToCreate, toUpdate, setToUpdate);
  };

  const onCompanyStageCheckboxClick = (id) => {
    return onCheckboxClick(id, talentCompanyStageValues, setTalentCompanyStageValues, toCreate, setToCreate, toUpdate, setToUpdate);
  };

  const onClickSave = () => {
    const response = onUpdate(toCreate, toUpdate);
    if (response) {
      setToUpdate([]);
      setToCreate([]);
      setDisplayActionBar(false);
    }
  };

  const onClickCancel = () => {
    setToUpdate([]);
    setToCreate([]);
    setTalentIndustryValues([...talentIndustryValues]);
    setTalentCompanyStageValues([...talentCompanyStageValues]);
    setDisplayActionBar(false);
  };

  return (
    <div className="bg-white rounded-xl px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl font-bold">{$.industries_title}</div>
      </div>
      <div className="flex flex-col">
        <div className="pb-2">
          <Label className="font-normal text-darkest-navy text-sm sm:text-base">{$$.industries_helper}</Label>
        </div>
        <div className="ratingContainer bg-lightest-grey rounded-xl p-2 mb-2 sm:px-4 overflow-y-scroll">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen(open === 'industry' ? '' : 'industry')}>
            <B2DB className="flex flex-1 items-center gap-2">
              Industries and Business Models
              <Pill value={ratedIndustries.length + RSUIndustries.length} />
            </B2DB>
            {open === 'industry' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'industry' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
            <RatingHelper label={'Industry'} showDisplayOnProfile />
            {ratedIndustries.map(q =>
              <Qualification
                key={q.id}
                qualification={q}
                rating={talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                onRatingChange={onIndustryRatingChange}
                showCheckbox
                disabled={isUpdating}
                checkboxDisabled={isUpdating ||
                  !talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating ||
                  talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0 ||
                  (displayCount >= 10 && talentIndustryValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile === false)
                }
                onCheckboxClick={() => onIndustryCheckboxClick(q.id)}
                checked={talentIndustryValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
              />
            )}
            {RSUIndustries.map(q =>
              <Qualification
                key={q.id}
                qualification={q}
                rating={talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                onRatingChange={onIndustryRatingChange}
                showCheckbox
                disabled={isUpdating}
                checkboxDisabled={isUpdating ||
                  !talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating ||
                  talentIndustryValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0 ||
                  displayCount >= 10
                }
                onCheckboxClick={() => onIndustryCheckboxClick(q.id)}
                checked={talentIndustryValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
              />
            )}
          </div>
          }
        </div>
        <div className="ratingContainer bg-lightest-grey rounded-xl p-2 mb-2 sm:px-4 overflow-y-scroll">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen(open === 'companyStage' ? '' : 'companyStage')}>
            <B2DB className="flex flex-1 items-center gap-2">
              Company Stages
              <Pill value={ratedCompanyStages.length + RSUCompanyStages.length} />
            </B2DB>
            {open === 'companyStage' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'companyStage' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Company Stage'} showDisplayOnProfile />
              {ratedCompanyStages.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onCompanyStageRatingChange}
                  showCheckbox
                  disabled={isUpdating}
                  checkboxDisabled={
                    isUpdating ||
                    !talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating ||
                    talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0 ||
                    (displayCount >= 10 && talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile === false)
                  }
                  onCheckboxClick={() => onCompanyStageCheckboxClick(q.id)}
                  checked={talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
              )}
              {RSUCompanyStages.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating || null}
                  onRatingChange={onCompanyStageRatingChange}
                  showCheckbox
                  disabled={isUpdating}
                  checkboxDisabled={
                    isUpdating ||
                    !talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating ||
                    talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.talent_rating === 0 ||
                    displayCount >= 10
                  }
                  onCheckboxClick={() => onCompanyStageCheckboxClick(q.id)}
                  checked={talentCompanyStageValues.find(qual => qual.qualification_id === q.id)?.display_on_talent_profile || false}
                />
            )}
          </div>
          }
        </div>
      </div>
      <ActionBar displayActionBar={displayActionBar} isUpdating={isUpdating} onClickCancel={onClickCancel} onClickSave={onClickSave} />
      <PromptUpdater />
    </div>
  )
};

export default Industries;
