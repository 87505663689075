import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {resetPasswordConfirm} from "utils/api";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {Input} from "../../components/Form";
import {useHistory} from "react-router-dom";
import {Button} from "./components";
import loginArt from "../../assets/login_art.png";

const ResetPasswordConfirm = ({ setLogged }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  useEffect(() => {
    document.title = "Password Reset";
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPasswordConfirm = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('user');
      const token = urlParams.get('token');
      if (!userId || !token) {
        alert.error('user and token are required');
        return;
      }
      await resetPasswordConfirm(
        userId,
        token,
        { password }
      );
      alert.success('Password reset successful');
      await new Promise(r => setTimeout(r, 2000));
      history.push(`/login`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  }

  return (
    <div className="font-lato bg-alice-blue w-screen h-screen flex items-center justify-center px-24 py-12 text-dark-navy">
      <div className="rounded-2xl bg-white relative w-300 h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="max-w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1
              className="font-semibold text-2xl mb-4"
            >Set your new password</h1>
            <label className="text-sm text-left w-full mb-1">
              Enter Password
            </label>
            <div className="w-full mb-3">
              <Input
                type="password"
                fontSize="text-sm"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <label
              className="text-sm text-left w-full mb-1"
            >
              Confirm Password
            </label>
            <div className="w-full mb-4">
              <Input
                type="password"
                fontSize="text-sm"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <Button
              className="w-full"
              disabled={!password || !confirmPassword || password !== confirmPassword}
              onClick={handleResetPasswordConfirm}
              large
            >Confirm</Button>
            <p className="mb-4 mt-4 text-sm text-center w-full text-kasmir">
              Having trouble logging in? {" "}
              <a
                href="mailto:talent@rightsideup.co"
                className="underline text-link hover:text-link-dark"
              >Contact support</a>
            </p>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-r-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4 mt-10 gap-y-3 flex flex-col">
              <h1 className="text-2.5xl font-bold">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="h-full flex items-end">
              <img className="" src={loginArt} alt="rsu art 1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordConfirm;
