import React, {useMemo} from "react";
import $ from "strings/talent";
import {EditBtn} from "components/Buttons";
import {
  QualificationsDisplay
} from "../../../components/QualificationsDisplay";
import {getUrlSuffix} from "../../../utils/localStorageService";
import {useHistory} from "react-router-dom";
import placeholderImg from "../assets/company_stages_placeholder.png";

const CompanyStages = ({ talentData, setTalentData, isPublicProfile }) => {
  const { qualifications } = talentData;
  const userSuffix = getUrlSuffix();

  const history = useHistory();

  const stages = useMemo(() => {
    if (!qualifications) return;
    return qualifications.filter(
      q => q.qualification?.type.toLowerCase() === 'company_stage' && q.display_on_talent_profile
    ).map(q => q.qualification);
  }, [qualifications, isPublicProfile]);

  return (
    <>
    { isPublicProfile && !stages?.length ? <></> :
    <div className="bg-white rounded-extra-large p-4 gap-y-3.5 flex flex-col">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold">Company Stages</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn onClick={() => {
            const userSuffix = getUrlSuffix();
            history.push(`/talent/${userSuffix}/expertise?tab=industries:companyStage`);
          }} />
        </div>}
      </div>
      <div className="text-sm">
        {stages.length ?
          <div className="text-sm flex gap-4">
            <QualificationsDisplay qualifications={stages} large />
          </div> :
          !isPublicProfile &&
          <div className="flex flex-col">
            <div className="h-20 flex justify-center">
              <img src={placeholderImg} className="object-contain" alt="Add a project" />
            </div>
            <div
              className="mx-auto w-4/5 text-kasmir text-center pb-2"
              dangerouslySetInnerHTML={{
                __html: $.company_stages_placeholder.replace("#", `<a class="text-link underline cursor-pointer" href="/talent/${userSuffix}/expertise?tab=industries:companyStage">My Expertise tab</a>`)
              }}
            />
          </div>
        }
      </div>
    </div>
    }
    </>
  )
};

export default CompanyStages;
