import React, { useMemo } from "react";
import $ from "strings/talent";
import { EditBtn } from "components/Buttons";
import { QualificationsDisplay } from "../../../components/QualificationsDisplay";
import { getUrlSuffix } from "../../../utils/localStorageService";
import { useHistory } from "react-router-dom";
import placeholderImg from "../assets/industries_placeholder.png";

const HIDDEN_INDUSTRY_IDS = [
  "57ddc7ac-da7e-4eee-946c-0b697e0819e8", // International Talent - not user facing
];

const Industries = ({ talentData, isPublicProfile }) => {
  const { qualifications } = talentData;
  const userSuffix = getUrlSuffix();

  const history = useHistory();

  const industries = useMemo(() => {
    if (!qualifications) return;
    return qualifications
      .filter(
        (q) =>
          HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 &&
          q.qualification?.type.toLowerCase() === "industry" &&
          q.display_on_talent_profile
      )
      .map((q) => q.qualification);
  }, [qualifications, isPublicProfile]);

  if (isPublicProfile && !industries?.length) {
    return <></>;
  }

  return (
    <div className="bg-white rounded-extra-large p-4 gap-y-3.5 flex flex-col w-full">
      <div className="flex gap-2 items-center">
        <div className="text-lg font-bold">Industry Experience</div>
        {!isPublicProfile && (
          <div className="ml-auto mr-0">
            <EditBtn
              onClick={() => {
                const userSuffix = getUrlSuffix();
                history.push(
                  `/talent/${userSuffix}/expertise?tab=industries:industry`
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="text-sm">
        {industries.length ? (
          <div className="text-sm flex gap-4">
            <QualificationsDisplay qualifications={industries} large />
          </div>
        ) : (
          !isPublicProfile && (
            <div className="flex flex-col">
              <div className="h-20 flex justify-center">
                <img
                  src={placeholderImg}
                  className="object-contain"
                  alt="Add a project"
                />
              </div>
              <div
                className="mx-auto w-4/5 text-kasmir text-center pb-2"
                dangerouslySetInnerHTML={{
                  __html: $.industries_placeholder.replace(
                    "#",
                    `<a class="text-link underline cursor-pointer" href="/talent/${userSuffix}/expertise?tab=industries:industry">My Expertise tab</a>`
                  ),
                }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Industries;
