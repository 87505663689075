import React, { useState, useEffect, useMemo } from "react";
import { admin_talent as $ } from "strings";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import {
  BackLinkBtn,
  MergeButton,
  OutlinedButton,
} from "components/Buttons";
import UilUnion from "@iconscout/react-unicons/icons/uil-pathfinder-unite";
import UilTelegram from "@iconscout/react-unicons/icons/uil-telegram-alt";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import Overview from "./Overview/index";
import Notes from "./Notes/index";
import Attachments from "./Attachments/index";
import Experiences from "./Experiences";
import Projects from "./Projects";
import Roles from './Stakeholders';
import { usePageCache } from "contexts/pageCacheContext";
import {
  getTalentOwners,
  getAccountV2,
  getTalentCreatedProjectsV2,
  getTalentQualificationsV2
} from "utils/adminApi";
import Section from "components/Section";
import { toFormat } from "utils/date";
import { getTalentProfileCompletion } from "utils/misc";
import useCachedFetch from "hooks/useCachedFetch";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import DeleteForm from "../DeleteForm";
import Clockify from "../Clockify";
import { getAdminFlags } from "utils/localStorageService";
import InvitationForm from "../InvitationForm";
import TalentHeader from './Header';
import {useAlert} from "react-alert";

const Page = () => {
  const [modal, setModal] = useState();
  const { id } = useParams();
  const { cache } = usePageCache();
  const flags = getAdminFlags();
  const alert = useAlert();

  const { data, setData, pageState } = useCachedFetch(
    "admin_talent_v2",
    getAccountV2,
    id
  )

  const { data: experiencesData } = useCachedFetch("admin_talent_experiences", getTalentCreatedProjectsV2, id);

  const owners = useCachedFetch("admin_talent_roles", getTalentOwners, id);

  const talentQualifications = useCachedFetch(
    "admin_talent_qualifications_v2",
    getTalentQualificationsV2,
    id,
    { page_size: 9999 }
  );

  const talentProfileCompletion = useMemo(() => {
    if (!data || !experiencesData || !talentQualifications) return;
    let talentData = {
      ...data,
      talent_created_projects: experiencesData?.results,
      qualifications: talentQualifications?.data?.results
    }
    return getTalentProfileCompletion(talentData);
  }, [data, experiencesData, talentQualifications]);

  const talentOwners = useMemo(() => {
    let commaSeparatedOwners = '';
    if (owners?.data && Array.isArray(owners?.data?.results)) {
      owners?.data?.results.forEach(({owner}, i) => {
        commaSeparatedOwners += `${owner.first_name} ${owner.last_name}`;
        if (i !== owners?.data?.results.length - 1) commaSeparatedOwners += ', ';
      })
    }

    return commaSeparatedOwners;
  }, [owners.data]);

  useEffect(() => {
    if (!data) return;
    document.title = "Pipeline - " + data?.fullname;
  }, [data]);

  return (
    <Section id="admin_talent" className="h-full flex flex-col">
      <div className="flex items-center justify-between -mt-px">
        <BackLinkBtn to="/admin/talent" text={$.back_talent} />
        {!!data && (
          <div className="text-kasmir font-light text-xs text-right">
            {$.created_at_label}{" "}
            <span className="lowercase">
              {!!data?.created_at &&
                toFormat(new Date(data.created_at), "MM/dd/yyyy h:mma")}
            </span>
            <br />
            {$.updated_at_label}{" "}
            <span className="lowercase">
              {!!data?.updated_at &&
                toFormat(new Date(data.updated_at), "MM/dd/yyyy h:mma")}
            </span>
          </div>
        )}
      </div>
      <div className="bg-white flex flex-col">
        {pageState === "loading" || !data ? (
          <div className="p-2">
            <LoadPlaceholderGroup />
          </div>
        ) : ( <>
          {data &&
            <TalentHeader
              data={data}
              setData={setData}
              talentOwners={talentOwners}
              talentProfileCompletion={talentProfileCompletion}
            />
          }
          <div className="flex w-full bg-link-water">
            <div className="w-full flex flex-row flex-shrink-0 border-t border-b border-link-ligher">
              <div className="flex flex-1">
                <NavLink
                  exact
                  to={cache["url_talent_overview"] || `/admin/talent/${id}/overview`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.overview_link}
                </NavLink>
                <NavLink
                  exact
                  to={cache["url_talent_notes"] || `/admin/talent/${id}/notes`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.notes_link}
                </NavLink>
                <NavLink
                  exact
                  to={cache["url_talent_attachments"] || `/admin/talent/${id}/attachments`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.attachments_link} {` (${data.attachments_count})`}
                </NavLink>
                <NavLink
                  exact
                  to={cache["url_talent_experiences"] || `/admin/talent/${id}/experiences`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.experiences_link}
                </NavLink>
                <NavLink
                  exact
                  to={cache["url_talent_projects"] || `/admin/talent/${id}/projects`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.projects_v2_link}
                </NavLink>
                <NavLink
                  to={`/admin/talent/${id}/roles`}
                  className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                  activeClassName="font-bold"
                >
                  {$.roles_link}
                </NavLink>
                {flags.clockify_talent_enabled && (
                  <NavLink
                    to={`/admin/talent/${id}/clockify`}
                    className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-r border-caded"
                    activeClassName="font-bold"
                  >
                    {$.clockify_link}
                  </NavLink>
                )}
              </div>
              <div className="flex items-center gap-2">
                <MergeButton
                  to={`/admin/talent/${id}/merge`}
                  className="ml-2"
                >
                  <UilUnion size="16" className="mr-1" />
                  {$.merge_btn}
                </MergeButton>
                <OutlinedButton onClick={() => setModal("invite")}>
                  <UilTelegram size="16" className="mr-1" />
                  {data.onboard_last_email_at
                    ? $.resend_invite_btn
                    : $.invite_btn}
                </OutlinedButton>
                <OutlinedButton onClick={() => {
                  const baseURL = window.location.origin;
                  let suffix = data.url_suffix;
                  const publicURL = `${baseURL}/talent/${suffix}`;
                  navigator.clipboard.writeText(publicURL);
                  alert.success('Copied to clipboard', { timeout: 2000 });
                }}>
                  <UilCopy size="16" className="mr-1" />
                  {$.copy_public_url_btn}
                </OutlinedButton>
                <button type="button" className="group focus:outline-none h-5" onClick={() => setModal("delete")}>
                  <span
                    className="group-focus:ring focus:outline-none text-sm font-bold rounded px-1 h-5 inline-flex items-center justify-center text-red-dark bg-white hover:bg-red-dark active:bg-red-darker hover:text-white"
                    tabIndex="-1"
                  >
                    <UilTrashAlt size="20" className="mr-1" />
                    {$.delete_talent_btn}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>)}
      </div>
      <div className="flex-1 min-h-0">
        <div className="flex-1 h-full">
          <Switch>
            <Route exact path="/admin/talent/:id">
              <Overview data={data} setData={setData} talentQualifications={talentQualifications} />
            </Route>
            <Route path="/admin/talent/:id/overview">
              <Overview data={data} setData={setData} talentQualifications={talentQualifications} />
            </Route>
            <Route path="/admin/talent/:id/notes">
              <Notes data={data} />
            </Route>
            <Route path="/admin/talent/:id/attachments">
              <Attachments
                data={data}
                setData={setData}
              />
            </Route>
            <Route path="/admin/talent/:id/experiences">
              <Experiences
                data={experiencesData}
              />
            </Route>
            <Route path="/admin/talent/:id/projects">
              <Projects
                data={data}
                setData={setData}
              />
            </Route>
            <Route path="/admin/talent/:id/roles">
              <Roles
                talentOwners={talentOwners}
                owners={owners}
                data={data}
                setData={setData}
              />
            </Route>
            <Route path="/admin/talent/:id/clockify">
              <Clockify
                data={data}
                setData={setData}
              />
            </Route>
          </Switch>
        </div>
      </div>
      {modal === "delete" && <DeleteForm data={data} onClose={setModal} />}
      {modal === "invite" && (
        <InvitationForm data={data} setData={setData} onClose={setModal} />
      )}
    </Section>
  );
};

export default Page;
