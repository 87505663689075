import React from "react";
import $ from "strings/talent";
import {Button} from "../components";

const ActionBar = ({ onClickCancel, onClickSave, isUpdating, displayActionBar }) => {
  return (
    <>
      {displayActionBar ?
        <div className="mt-4 mb-2 flex gap-x-2">
          <Button
            secondary
            className="text-sm sm:text-base"
            disabled={isUpdating}
            onClick={onClickCancel}
          >
            <span>{$.cancel_btn}</span>
          </Button>
          <Button
            className="text-sm sm:text-base"
            disabled={isUpdating}
            onClick={onClickSave}
          >
            <span>{$.save_btn}</span>
          </Button>
        </div> :
        <div style={{ height: '64px' }} />
      }
    </>
  )
};

export default ActionBar;
