import React, { useState } from "react";
import Modal from "../components/Modal";
import $ from "strings/talent";
import { isBodyEmpty } from "components/RichTextEditor";
import { serialize, deserialize } from "utils/htmlSerializer";
import RichTextEditor from "components/RichTextEditorNew";
import { patchTalentTalent } from "utils/api";

const AboutMeForm = ({
  onClose,
  talentData,
  setTalentData
}) => {
  const { about_me } = talentData;
  const [isUpdating, setIsUpdating] = useState(false)
  const [values, setValues] = useState({
    about_me: deserialize(
      new DOMParser().parseFromString(
        about_me && about_me.includes("<p>")
          ? about_me
          : (about_me || "")
              .split("\n")
              .map((t) => "<p>" + t + "</p>")
              .join(""),
        "text/html"
      ).body
    ),
  });

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.about_me);

  const onClickSave = async () => {
    const originalValues = { ...talentData };
    const userId = talentData?.user?.id;
    if (!userId) return;
    setIsUpdating(true);

    let about_me = serialize({ children: values.about_me });
    about_me = about_me === "<p></p>" ? null : about_me;
    const toSend = { about_me };

    try {
      const response = await patchTalentTalent(userId, toSend);
      setTalentData({ ...talentData, ...toSend })
      setIsUpdating(false);
      onClose();
      return response;
    } catch (e) {
      setTalentData({ ...originalValues })
      setIsUpdating(false);
      console.error({e})
    }
  };

  const onClickCancel = () => {
    updateVal("about_me", "");
    onClose();
  }

  return (
    <Modal
      title={$.about_me_title}
      onClose={onClickCancel}
      onClickCancel={onClickCancel}
      onClickSave={onClickSave}
      // buttonsDisabled={isEmpty || isUpdating}
      id="about_me_form"
    >
    <div className="flex-1 min-h-0 overflow-y-auto pt-1.5 pb-2.5 text-midnight">
      <div className="pb-2.5">
        <div className="text-sm font-bold mb-1">{$.describe_yourself_label}</div>
        <div className="text-xs text-kasmir">{$.describe_yourself_helper}</div>
      </div>
      <RichTextEditor
        alwaysOpen
        placeholder={$.describe_yourself_placeholder}
        value={values.about_me}
        onChange={(v) => updateVal("about_me", v)}
        className="border-b h-36"
      />
    <div className="text-xs mt-2.5">
      Feeling stumped? Get inspiration and check out&nbsp;
      <a
        href="https://rightsideup.slite.page/p/ifhzvhQtHHY_pa/Writing-an-effective-About-Me-paragraph"
        target="_blank"
        className="text-electric-indigo"
      >some examples!</a>
    </div>
    </div>
    </Modal>
  );
};

export default AboutMeForm;
