import React from "react";
import { not_found as $ } from "strings";
import bgNotFound from "assets/bg404.png";

const NotFoundPage = () => (
  <div
    id="NotFound"
    className="font-lato flex items-center justify-center text-center bg-alice-blue flex w-full h-full relative"
  >
    <img src={bgNotFound} alt="404" />
    <div className="absolute" style={{
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }}>
      <div className="flex flex-col gap-4">
        <div className="text-midnight text-4xl font-extrabold">{$.not_found_title}</div>
        <div className="text-kasmir font-medium">{$.not_found_message}</div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
