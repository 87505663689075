import React, {useEffect, useState} from "react";
import {admin_login as $} from "strings";
import {resetPassword} from "utils/api";
import logoSm from "assets/logo_sm.svg";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {useHistory} from "react-router-dom";
import {Button} from "./components";
import loginArt from "../../assets/login_art.png";

const ResetPasswordSuccess = ({ setLogged }) => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    document.title = "Password Reset Confirmation";
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPassword = async () => {
    if (!email) {
      history.push(`/login`);
      return;
    }
    try {
      const response = await resetPassword({ email });
      alert.success(response.message);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
   }
  };

  return (
    <div className="font-lato bg-alice-blue w-screen h-screen flex items-center justify-center px-24 py-12">
      <div className="rounded-2xl bg-white relative w-300 h-full">
        <div className="absolute top-0 left-0 h-10 w-10 ml-8 mt-8">
          <img
            className="max-w-full max-h-full"
            src={logoSm}
            alt="Right Side Up"
          />
        </div>
        <div className="w-full h-full flex">
          <div
            className="w-1/2 h-full flex flex-col items-center justify-center px-10"
          >
            <h1
              className="font-semibold text-2xl"
            >Check your inbox</h1>
            <h2 className="text-md text-center text-kasmir mb-4">
              An email has been sent to {email || 'your inbox'}
            </h2>
            <div className="w-full mb-4 bg-lightest-grey rounded-lg p-4">
              <h3 className="text-lg">Didn’t receive an email in your inbox?</h3>
              <p className="text-md text-kasmir">
                We can only send password reset instructions to registered emails with an existing
                account. If the email doesn't appear in your inbox or spam folder, please check
                the email you entered or click below to send a new email.
              </p>
            </div>
            <Button
              className="w-full"
              onClick={handleResetPassword}
              large
            >Resend Email</Button>
            <p className="mb-4 mt-4 text-sm text-center w-full text-kasmir">
              Having trouble logging in? {" "}
              <a
                href="mailto:talent@rightsideup.co"
                className="underline text-link hover:text-link-dark"
              >Contact support</a>
            </p>
          </div>
          <div
            className="w-1/2 h-full bg-lightest-grey rounded-r-2xl pt-10 text-center flex flex-col justify-between"
          >
            <div className="px-8 h-1/4 mt-10 gap-y-3 flex flex-col">
              <h1 className="text-2.5xl font-bold">
                Be your own boss, boss
              </h1>
              <p className="text-sm mt-2">
                Deliver excellent work that thrills clients by taking on
                projects
                that light you up. Whether you want to moonlight or freelance
                full
                time, you dictate who you work with, how many hours you work,
                and how much you get.
              </p>
            </div>
            <div className="h-full flex items-end">
              <img className="" src={loginArt} alt="rsu art 1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
