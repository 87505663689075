import React, { useState } from "react";
import $ from "strings/talent";
import {Input} from "components/Form";
import {CloseXButton} from "../../../components/Buttons";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {FormErrorMessage, Label, SettingsActionBar} from "../components";
import {loginPassword, patchTalentUser} from "../../../utils/api";
import AlertError from "components/AlertError";
import {useAlert} from "react-alert";
import {yupPasswordSchema} from "../../../utils/validation";


const PASSWORD_INPUTS = [
  {
    name: 'oldPassword',
    label: $.old_password_label,
    placeholder: $.old_password_placeholder
  },
  {
    name: 'newPassword',
    label: $.new_password_label,
    placeholder: $.new_password_placeholder
  },
  {
    name: 'confirmPassword',
    label: $.confirm_password_label,
    placeholder: $.confirm_password_placeholder
  }
];


const LoginCredentials = ({talentData, setTalentData, onUpdate}) => {
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const alert = useAlert();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const checkOldPassword = async (oldPassword) => {
    try {
      const email = talentData.user.email;
      if (!email) {
        return alert.error(<AlertError message="Email not found"/>);
      }
      await loginPassword({ email, password: oldPassword });
      return true;
    } catch (e) {
      return false;
    }
  };

  const updatePassword = async (values) => {
    try {
      await patchTalentUser(talentData.user.id, {password: values.newPassword});
      setShowPasswordChangeModal(false);
      onUpdate();
    } catch (e) {
      alert.error(<AlertError error={e} />);
    }
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("This field is required").test('checkOldPassword', 'Incorrect password', checkOldPassword),
    newPassword: yupPasswordSchema,
    confirmPassword: yup.string().required("This field is required").oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });

  const ChangePasswordModal = () => {
    return (
      <div
        className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={() => setShowPasswordChangeModal(false)}
      >
        <div
          className="bg-white rounded-xl w-85"
          onClick={e => e.stopPropagation()}
        >
          <div className="px-6 border-b flex justify-between">
            <h2 className="text-2xl font-bold my-5">Change Password</h2>
            <CloseXButton
              className="my-5"
              onClick={() => setShowPasswordChangeModal(false)}
            />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={() => {}}
            enableReinitialize={true}
          >
            {({
              setFieldValue,
              values,
              touched,
              setTouched,
              setFieldTouched,
              errors,
              setFieldError,
              validateForm
            }) => {
              const onChange = async e => {
                const { name, value } = e.target;
                await setFieldValue(name, value);
                await setFieldTouched(name, value !== initialValues[name]);
                if (errors[name]) {
                  setFieldError(name, null)
                }
              };
              return (
                <Form className="form pt-4">
                  {PASSWORD_INPUTS.map(({name, label, placeholder}) => (
                    <div className="px-6 pb-4" key={name}>
                      <div className="flex flex-row">
                        <div className="flex-col w-full mr-4">
                          <Label>{label}</Label>
                          <Input
                            name={name}
                            type="password"
                            value={values[name]}
                            error={errors[name]}
                            placeholder={placeholder}
                            onChange={onChange}
                          />
                          <FormErrorMessage error={errors[name]}/>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="px-6 py-3 flex border-t">
                    <SettingsActionBar
                      className="ml-auto"
                      saveDisabled={!Object.values(touched).some(Boolean)}
                      onCancel={() => setShowPasswordChangeModal(false)}
                      onSave={async () => {
                        const errors = await validateForm();
                        if (!Object.keys(errors).length) {
                          await updatePassword(values);
                        }
                      }}
                    />
                  </div>
                </Form>
              )}}
          </Formik>
        </div>
      </div>
    )
  };

  return (
    <>
      {showPasswordChangeModal && <ChangePasswordModal/>}
      <div className="flex flex-row">
        <div className="flex-col w-1/2 mr-4">
          <Label>{$.email_label}</Label>
          <Input
            name="firstName"
            value={talentData?.user?.email || ''}
            disabled
          />
          <p className="text-sm text-gray-500 mt-2">
            Please contact&nbsp;
            <a
              className="underline text-electric-indigo"
              href="mailto:support@rightsideup.co"
            >support@rightsideup.co</a>
            &nbsp;to change your login email
          </p>
          <button
            className="text-electric-indigo mt-4 underline"
            onClick={() => setShowPasswordChangeModal(true)}
          >
            Change password
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginCredentials;
