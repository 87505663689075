import React, { useState, useMemo, useEffect } from "react";
import $ from "strings/talent";
import { Label, Sublabel } from "components/Form";
import { B2DB } from "components/Typography"
import {
  Error,
  Qualification,
  RatingHelper,
  Pill
} from "../../components/index";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";

const Skills = ({
  setFieldValue,
  values,
  errors,
  qualifications,
  setFieldError
}) => {
  const [open, setOpen] = useState('specialty');
  
  const specialtiesArray = useMemo(() => {
    if (!values?.specialties?.length) {
      setOpen('other');
      return [];
    } else {
      const arr = values.specialties.split('|');
      return arr;
    }
  }, [values]);
  
  const [specialtySkills, otherSkills] = useMemo(() => {
    let specialty = [];
    let other = [];
    
    const skills = qualifications.filter(q => q.type.toLowerCase() === 'skill');
    skills.forEach((s) => {
      if (specialtiesArray.some((q) => s.grouping.includes(q))) {
        specialty.push(s);
      } else {
        other.push(s);
      }
    });
    
    specialty.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    other.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    
    return [specialty, other];
  }, [specialtiesArray, qualifications])
  

  const onRatingChange = (id, rating) => {
    const talentQualifications = values.qualificationsRatings;
    let newTalentQualifications;
    if (rating === null || rating === 0) {
      newTalentQualifications = talentQualifications;
      delete newTalentQualifications[id];
    } else {
      newTalentQualifications = {...talentQualifications, [id]: parseInt(rating) };
      if (errors['qualificationsRatings']) {
        setFieldError('qualificationsRatings', null)
      }
    }
    setFieldValue('qualificationsRatings', newTalentQualifications);
  };

  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.skills_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.expertise_subtitle}</div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2">
          <Label className="font-medium text-darkest-navy text-sm sm:text-base">{$.skills_label}</Label>
          <Sublabel>{$.rate_any_sublabel}</Sublabel>
        </div>
        {(specialtiesArray?.length > 0) && <div className="ratingContainer bg-white rounded-xl mb-2 p-2 sm:px-4">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('specialty')}>
            <B2DB className="flex flex-1 items-center gap-2">
              {$.specialty_skills_label}
              <Pill value={specialtySkills.length} />
            </B2DB>
            {open === 'specialty' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'specialty' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Skill'}/>
              {specialtySkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={values.qualificationsRatings[q.id] || null}
                  onRatingChange={onRatingChange}
                />
              )}
            </div>
            }
          </div>
        }
        <div className="ratingContainer bg-white rounded-xl p-2 sm:px-4">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('other')}>
            <B2DB className="flex flex-1 items-center gap-2">
              {(specialtiesArray?.length > 0) ? $.other_skills_subheader : $.skills_title}
              <Pill value={otherSkills.length} />
            </B2DB>
            {open === 'other' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'other' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Skill'}/>
              {otherSkills.map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={values.qualificationsRatings[q.id] || null}
                  onRatingChange={onRatingChange}
                />
              )}
            </div>
          }
        </div>
      </div>
      {errors.qualificationsRatings ? <Error msg={errors.qualificationsRatings} /> : <div style={{height: '22px'}} />}
    </div>
  )
};

export default Skills;
