import React from "react";
import { useParams } from "react-router-dom";
import Section from "components/Section";
import AboutMe from "./AboutMe";
import Qualifications from "./Qualifications";
import Preferences from "./Preferences";
import Onboarding from "./Onboarding";
import useQualifications from "hooks/useQualifications";

const Overview = ({ data, setData, talentQualifications }) => {
  const { id } = useParams();
  const { qualifications } = useQualifications(true);

  return (
    <Section id="admin_talent_overview" className="flex mt-px h-full overflow-y-auto">
      <div className="bg-white flex-1 mr-2 h-full flex flex-col">
        <Qualifications
          talentQualifications={talentQualifications}
          qualificationsOptions={qualifications.qualificationsArray}
          user={id}
        />
      </div>
      <div className="w-1/3 h-full overflow-y-auto">
        {data && (
          <>
            <Onboarding user={id} talentData={data} setTalentData={setData} />
            <Preferences talentData={data} setTalentData={setData} />
            <AboutMe talentData={data} />
          </>
        )}
      </div>
    </Section>
  );
};

export default Overview;
