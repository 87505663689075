import React, { useState } from "react";
import { admin_talent_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import { urlify } from "utils/str";

const AboutMe = ({ talentData }) => {
  const [open, setOpen] = useState(false);

  const { about_me, first_name } = talentData;

  return (
    <Section id="admin_talent_about" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilUser className="mr-2" size="18" /> About {first_name ? first_name : 'Talent'}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!talentData && (
          <div className="px-2 pb-4">
            <div>
              <div
                className="text-sm font-normal"
                dangerouslySetInnerHTML={{ __html: about_me ? urlify(about_me) : $.description_placeholder}}
              />
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default AboutMe;
