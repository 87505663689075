import {remove} from "lodash";

export const onCheckboxClick = (id, values, setValues, toCreate, setToCreate, toUpdate, setToUpdate) => {
  const checked = document.getElementById(`checkbox--${id}`)?.checked;
  let newValues = [...values];
  let existingQualification = values.find(tool => tool.qualification_id === id);
  if (!existingQualification) return;
  let newVal = {
    qualification_id: existingQualification.qualification_id,
    qualification_name: existingQualification.qualification_name,
    talent_rating: existingQualification.talent_rating,
    display_on_talent_profile: checked
  };
  const create = toCreate.find(tool => tool.qualification_id === id);
  if (create) {
    let newToCreate = [...toCreate];
    remove(newToCreate, tool => tool.qualification_id === id);
    if (existingQualification.display_on_talent_profile !== checked) {
      newToCreate.push(newVal);
      setToCreate([...newToCreate]);
    }
  } else {
    let newToUpdate = [...toUpdate];
    newVal.talent_qualification_id = existingQualification.talent_qualification_id;
    remove(newToUpdate, tool => tool.qualification_id === id);
    if (existingQualification.display_on_talent_profile !== checked) {
      newToUpdate.push(newVal);
      setToUpdate([...newToUpdate]);
    }
  }
  remove(newValues, tool => tool.qualification_id === id);
  newValues.push(newVal);
  setValues([...newValues]);
};
