import React from "react";
import clsx from "clsx";
import { ErrorInfoIcon } from "../onboard/icons";

const Error = ({ msg, fontSize }) => {
  return (
    <div className="flex items-center py-px gap-2">
      <ErrorInfoIcon />
      <span className={clsx("text-red-darker font-medium whitespace-nowrap", fontSize || "text-sm")}>{msg}</span>
    </div>
  )
};

const FormErrorMessage = ({ error, emptyHeight='22px' }) => {
  return (
    <>{(!!error) ? <Error msg={error} /> : <div style={{height: emptyHeight}} />}</>
  )
};

export { Error, FormErrorMessage };
