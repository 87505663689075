import React, { useMemo, useState } from "react";
import $ from "strings/talent";
import { Label, Sublabel } from "components/Form";
import { B2DB } from "components/Typography"
import {
  Error,
  RatingHelper,
  Qualification,
  Pill
} from "../../components/index";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";

const HIDDEN_INDUSTRY_IDS = [
  '57ddc7ac-da7e-4eee-946c-0b697e0819e8' // International Talent - not user facing  
];

const Industries = ({
  setFieldValue,
  values,
  errors,
  setFieldError,
  qualifications
}) => {
  const [open, setOpen] = useState('industries');
  const industries = useMemo(() => {
    return qualifications.filter(q => HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 && q.type.toLowerCase() === 'industry');
  }, [qualifications]);

  const companyStages = useMemo(() => {
    return qualifications.filter(q => q.type.toLowerCase() === 'company_stage');
  }, [qualifications]);


  const onIndustryRatingChange = (id, rating) => {
    const talentIndustries = values.industries;
    let newTalentIndustries;
    if (rating === null || rating === 0) {
      newTalentIndustries = talentIndustries;
      delete newTalentIndustries[id];
    } else {
      newTalentIndustries = {...talentIndustries, [id]: parseInt(rating) };
      if (errors['industries']) {
        setFieldError('industries', null)
      }
    }
    setFieldValue('industries', newTalentIndustries);
  };

  const onCompanyStageRatingChange = (id, rating) => {
    const talentCompanyStages = values.companyStages;
    let newTalentCompanyStages;
    if (rating === null || rating === 0) {
      newTalentCompanyStages = talentCompanyStages;
      delete newTalentCompanyStages[id];
    } else {
      newTalentCompanyStages = {...talentCompanyStages, [id]: parseInt(rating) };
      if (errors['companyStages']) {
        setFieldError('companyStages', null)
      }
    }
    setFieldValue('companyStages', newTalentCompanyStages);
  };

  return (
    <div className="px-8 py-4 grow">
      <div className="py-2 mb-auto">
        <div className="text-darkest-navy text-2xl sm:text-3xl font-bold py-2">{$.industries_title}</div>
        <div className="text-midnight text-base sm:text-lg font-normal">{$.expertise_subtitle}</div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2">
          <Label className="font-medium text-darkest-navy text-sm sm:text-base">{$.industries_label}</Label>
          <Sublabel>{$.rate_any_sublabel}</Sublabel>
        </div> 
        {industries && <div className="ratingContainer bg-white rounded-xl mb-2 p-2 sm:px-4 overflow-y-scroll">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('industries')}>
            <B2DB className="flex flex-1 items-center gap-2">
              Industries and Business Models
              <Pill value={industries.length} />
            </B2DB>
            {open === 'industries' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'industries' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Industry'}/>
              {industries.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={values.industries[q.id] || null}
                  onRatingChange={onIndustryRatingChange}
                />
              )}
            </div>
          }
          </div>
        }
        {companyStages && <div className="ratingContainer bg-white rounded-xl p-2 mb-2 sm:px-4 overflow-y-scroll">
          <div className="flex items-center cursor-pointer" onClick={() => setOpen('companyStages')}>
            <B2DB className="flex flex-1 items-center gap-2">
              Company Stages
              <Pill value={companyStages.length} />
            </B2DB>
            {open === 'companyStages' ? <UilUp size="24" /> : <UilDown size="24" />}
          </div>
          {open === 'companyStages' &&
            <div className="ratings flex flex-col py-2 gap-2 overflow-y-scroll">
              <RatingHelper label={'Industry'}/>
              {companyStages.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(q =>
                <Qualification
                  key={q.id}
                  qualification={q}
                  rating={values.companyStages[q.id] || null}
                  onRatingChange={onCompanyStageRatingChange}
                />
              )}
            </div>
            }
          </div>
        }
      </div>
      {errors.industries ? <Error msg={errors.industries} /> : <div style={{height: '22px'}} />}
      {errors.companyStages ? <Error msg={errors.companyStages} /> : <div style={{height: '22px'}} />}
    </div>
  )
};

export default Industries;
