import React from "react";

const Pill = ({ value }) => {
  return (
    <div className="bg-dark-navy rounded-large text-white text-mini px-2.5 h-4.75 items-center flex">
      {value}
    </div>
  )
}

export default Pill;
