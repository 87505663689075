import React from "react";
import clsx from "clsx";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";

const Toggle = ({ isOn, onClick, isUpdating }) => {

  return (
    <div
      className={clsx(
        'w-14 p-0.5 select-none rounded-full flex items-center transition-colors',
        isUpdating ? 'cursor-wait' : 'cursor-pointer',
        isOn ? 'bg-electric-indigo' : 'bg-gray-300'
      )}
      onClick={isUpdating ? () => {} : onClick}
    >
      <div
        className={`w-6 h-6 bg-white flex rounded-full items-center justify-center transition-transform transform ${
          isOn ? "translate-x-7" : "translate-x-0"
        }`}
      >
        {isOn ? (
          <UilCheck size="20" />
        ) : (
          <div>&times;</div>
        )}
      </div>
    </div>
  );
};

export default Toggle;
