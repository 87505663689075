import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import $ from "strings/talent";
import $$ from "strings/talent";
import {ExpertiseIcon, LoginCredentialsIcon, ProfileSettingsIcon} from "pages/talent/onboard/icons";
import ContactInformation from "./ContactInformation";
import LoginCredentials from "./LoginCredentials";
import EmploymentStatus from "./EmploymentStatus";
import OpportunityPreferences from "./OpportunityPreferences";
import SearchEngineOptimization from "./SearchEngineOptimization";
import Availability from "./Availability";
import Experience from "./Experience";
import toast, { Toaster } from 'react-hot-toast';
import Toast from "../components/Toast";
import {getUrlSuffix} from "../../../utils/localStorageService";
import Modal from "../components/Modal"
import NavigationPrompt from "react-router-navigation-prompt";
import { Loader } from "../onboard/screens";

const SETTINGS_SCREENS = {
  "contact": {
    icon: <ExpertiseIcon />,
    activeIcon: <ExpertiseIcon active={true} />,
    title: $.contact_title,
  },
  "credentials": {
    icon: <LoginCredentialsIcon />,
    activeIcon: <LoginCredentialsIcon active={true} />,
    title: $.credentials_title,
  },
  "profile_settings": {
    icon: <ProfileSettingsIcon />,
    activeIcon: <ProfileSettingsIcon active={true} />,
    title: $.profile_settings_title,
    subsections: {
      "seo": {
        title: $$.seo_section_title,
        page_title: $$.seo_page_title,
      },
      "employment": {
        title: $$.employment_status_title,
      },
      "opportunity": {
        title: $$.opportunity_preferences_title,
      },
      "availability": {
        title: $$.availability_title,
      },
      "experience": {
        title: $$.experience_title,
      }
    }
  }
};

const SettingsPage = ({ talentData, setTalentData }) => {
  const [activeScreen, setActiveScreen] = useState();
  const [activeSubsection, setActiveSubsection] = useState('seo');
  const [pageSubtitle, setPageSubtitle] = useState();
  const [showNavPrompt, setShowNavPrompt] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const userSuffix = getUrlSuffix();

  const renderActiveScreen = () => {
    const pageUrl = window.location.href;
    if (pageUrl.includes('tab=contact')) {
      setActiveScreen('contact');
    } else if (pageUrl.includes('tab=credentials')) {
      setActiveScreen('credentials');
    } else if (pageUrl.includes('tab=profile')) {
      setActiveScreen('profile_settings');
      if (pageUrl.includes('seo')) {
        setActiveSubsection('seo');
      } else if (pageUrl.includes('employment')) {
        setActiveSubsection('employment');
      } else if (pageUrl.includes('opportunity')) {
        setActiveSubsection('opportunity');
      } else if (pageUrl.includes('availability')) {
        setActiveSubsection('availability');
      } else if (pageUrl.includes('experience')) {
        setActiveSubsection('experience');
      }
    } else {
      setActiveScreen('contact');
    }
  }

  useEffect(() => {
    renderActiveScreen();
  }, []);

  useEffect(() => {
    renderActiveScreen();
  }, [activeScreen, showNavPrompt, search]);

  useEffect(() => {
    if (!activeScreen) return;
    let title;
  
    if (SETTINGS_SCREENS[activeScreen].subsections) {
      const subsection = SETTINGS_SCREENS[activeScreen].subsections[activeSubsection];
      title = subsection.page_title || subsection.title;
    } else {
      title = SETTINGS_SCREENS[activeScreen].title
    }
    setPageSubtitle(title);
  }, [activeScreen, activeSubsection]);

  const onNavClick = (e, screen) => {
    if (!showNavPrompt) {
      if (activeScreen === 'profile_settings' && screen !== 'profile_settings') {
        setActiveSubsection('seo')
      }
      setActiveScreen(screen);
    }
    history.push(`/talent/${userSuffix}/settings?tab=${screen}`);
  };

  const onSubsectionClick = (subsection) => {
    if (!showNavPrompt) {
      setActiveSubsection(subsection);
    }
    history.push(`/talent/${userSuffix}/settings?tab=profile_settings:${subsection}`);
  };

  const displayToast = (sectionStr) => {
    toast(<Toast message={`${sectionStr} saved successfully`} />);
  }

  const NavItem = ({name, onNavClick}) => {
    return (
      <div
        className={clsx("flex flex-col justify-center w-full sm:py-2 ", activeScreen === name ? "flex-3 sm:flex-1" : "flex-1")}>
        <div
          className={clsx("flex cursor-pointer items-center p-3", (activeScreen === name && !SETTINGS_SCREENS[name].subsections) && "sm:bg-lightest-grey sm:rounded-lg ", (activeScreen === name) && " text-electric-indigo")}
          onClick={(e) => onNavClick(e, name)}
        >
          <div className="h-full bg-lightest-grey sm:bg-transparent rounded-lg pr-2">
            {activeScreen === name ? SETTINGS_SCREENS[name].activeIcon : SETTINGS_SCREENS[name].icon}
          </div>
          <div className={clsx("ml-2 text-sm sm:text-base font-semibold", activeScreen === name ? "text-electric-indigo " : "hidden sm:flex sm:text-kasmir")}>
            {SETTINGS_SCREENS[name].title}
          </div>
        </div>
        {(activeScreen === name && SETTINGS_SCREENS[name].subsections) &&
          <div className="flex flex-col my-2">
            {Object.keys(SETTINGS_SCREENS[name].subsections).map(subsection =>
              <div
                key={subsection}
                className="cursor-pointer"
                onClick={() => onSubsectionClick(subsection)}
              >
                <div className={
                  clsx("flex ml-8 py-2 font-medium text-sm text-kasmir ",
                  activeSubsection === subsection && "bg-lightest-grey rounded-lg text-electric-indigo")
                }>
                  <div className="pl-2">{SETTINGS_SCREENS[name].subsections[subsection].title}</div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    )
  };

  const Header = ({title, className}) => {
    return (
      <div
        className={`text-2xl font-bold ${className}`}
      >{title}</div>
    )
  };

  return (
    <div className="w-full h-full">
      {talentData ? 
        <div className="flex p-4">
          <div className="w-1/4">
            <div className="bg-white rounded-xl p-4">
              <Header className="py-3" title={$.settings_navlink} />
              {Object.keys(SETTINGS_SCREENS).map(screen =>
                <NavItem key={screen} name={screen} onNavClick={(e) => onNavClick(e, screen)} />
              )}
            </div>
          </div>
          <div className="w-3/4 pl-4">
            <div className="bg-white rounded-xl p-4">
              <Header className="mt-3 mb-6" title={pageSubtitle} />
              <div className="w-full">
                {{
                  'contact': <ContactInformation talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Contact information')} showNavPrompt={showNavPrompt} setShowNavPrompt={setShowNavPrompt} />,
                  'credentials': <LoginCredentials talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Login credentials')} setShowNavPrompt={setShowNavPrompt} />,
                  'profile_settings': {
                    'seo': <SearchEngineOptimization talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('SEO preferences')} setShowNavPrompt={setShowNavPrompt} />,
                    'employment': <EmploymentStatus talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Employment status')} setShowNavPrompt={setShowNavPrompt} />,
                    'opportunity': <OpportunityPreferences talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Opportunity preferences')} setShowNavPrompt={setShowNavPrompt} />,
                    'availability': <Availability talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Availability')} setShowNavPrompt={setShowNavPrompt} />,
                    'experience': <Experience talentData={talentData} setTalentData={setTalentData} onUpdate={() => displayToast('Experience')} setShowNavPrompt={setShowNavPrompt} />,
                  }[activeSubsection]
                }[activeScreen] || <div />}
              </div>
            </div>
          </div>
        </div> :
        <Loader />
      }
      <Toaster
        toastOptions={{
          style: {
            border: '1px solid ##e5f2ea',
            borderRadius: '5px',
            backgroundColor: '#e5f2ea',
            maxWidth: 500
          },
        }}
      />
      <NavigationPrompt when={(currentLocation, nextLocation) => {
        const promptIf = showNavPrompt && (
          currentLocation.pathname !== nextLocation.pathname || 
          currentLocation.search !== nextLocation.search
        )
        return promptIf;
      }}>
        {({ onConfirm, onCancel }) => (
          <Modal
            when={true}
            title="Discard Changes"
            onClose={onCancel}
            onClickCancel={onCancel}
            onClickSave={() => {
              onConfirm();
              setShowNavPrompt(false);
            }}
            saveBtnText="Discard Changes"
            showDelete={false}
            id="discard_changes_form--settings"
          >
            <div className="flex flex-col p-2 gap-4">
              <div className="text-base">{$.discard_changes_settings_message_1}</div>
              <div className="text-base">{$.discard_changes_message_2}</div>
            </div>
          </Modal>
        )}
      </NavigationPrompt>
    </div>
  );
};

export default SettingsPage;
