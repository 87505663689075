import React, {useEffect, useState} from "react";
import clsx from "clsx";
import $ from "strings/talent";
import {Checkbox, Radio} from "components/Form";
import {
  FormErrorMessage,
  Label,
  SettingsActionBar,
  Sublabel,
  Modal
} from "../components";
import {Form, Formik, useFormikContext} from "formik";
import {patchTalentTalent} from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import * as yup from "yup";
import {useAlert} from "react-alert";

const EMPLOYMENT_STATUS_OPTIONS = [
  {label: $.w2_employee_option, value: 'w2_employee'},
  {label: $.ft_freelancer_option, value: 'fulltime_freelancer'},
  {label: $.pt_freelancer_option, value: 'parttime_freelancer'},
  {label: $.student_option, value: 'student'},
  {label: $.searching_for_w2_option, value: 'searching_for_w2'},
];

const FULLTIME_FREELANCING_OPTIONS = [
  {label: $.yes_option, value: true},
  {label: $.no_option, value: false}
];

const BUSINESS_HOURS_OPTIONS = [
  {label: $.yes_option, value: 'yes'},
  {label: $.depends_on_day_option, value: 'depends_on_day'},
  {label: $.no_option, value: 'no'}
];

const EmploymentStatus = ({ talentData, setTalentData, onUpdate, setShowNavPrompt }) => {
  const [userId, setUserId] = useState()
  const [initialValues, setInitialValues] = useState({
    employmentStatus: null,
    openToFulltimeFreelance: null,
    businessHoursAvailable: null
  });
  const [isUpdating, setIsUpdating] = useState(false)

  const PromptUpdater = () => {
    const { dirty } = useFormikContext();
    useEffect(() => {
      const pageUrl = window.location.href;
      if (typeof dirty !== 'undefined') {
        if (dirty === true && pageUrl.includes("tab=profile_settings:employment")) {
          setShowNavPrompt(true)
        } else {
          setShowNavPrompt(false);
        }
      }
    }, [dirty])

    return null;
  }

  useEffect(() => {
    if (!talentData) {
      return;
    }
    setInitialValues({
      employmentStatus: talentData.employment_status,
      openToFulltimeFreelance: talentData.ft_freelance_interest,
      businessHoursAvailable: talentData.business_hours_available
    });
    setUserId(talentData.user?.id);
  }, [talentData]);

  const alert = useAlert();

  const processTalentType = (values) => {
    const { employmentStatus, openToFulltimeFreelance, businessHoursAvailable } = values;
    let talentType;

    if (employmentStatus.includes('searching_for_w2') && !openToFulltimeFreelance) {
      talentType = 'FTECandidateOnly'
    } else if (businessHoursAvailable === 'no') {
      talentType = 'Moonlighter'
    } else {
      if (employmentStatus.includes('w2_employee')) {
        talentType = 'FlexibleMoonlighter'
      } else if (!employmentStatus.includes('fulltime_freelancer')) {
        talentType = 'ConditionalFreelancer'
      } else {
        talentType = 'Freelancer'
      }
    }

    return talentType;
  }

  const processFTFreelanceInterest = (values) => {
    return values.employmentStatus?.includes('fulltime_freelancer') ? true : values.openToFulltimeFreelance;
  };

  const updateTalent = async (values) => {
    const data = {
      employment_status: values.employmentStatus,
      ft_freelance_interest: processFTFreelanceInterest(values),
      business_hours_available: values.businessHoursAvailable,
      talent_type: processTalentType(values)
    };
    try {
      const response = await patchTalentTalent(userId, data);
      setTalentData(response);
      setInitialValues(values);
      onUpdate();
    } catch (e) {
      console.error({ e, data });
      alert.error(<AlertError error="Something went wrong. Please try again, or contact us if the issue continues." />);
    }
  };

  const validationSchema = yup.object().shape({
    employmentStatus: yup
      .string()
      .required("Select at least one option"),
    businessHoursAvailable: yup
      .string()
      .required("Select an option"),
    openToFulltimeFreelance: yup
      .string()
      .when(["employmentStatus"], {
        is: (employmentStatus) =>
          !employmentStatus?.includes('fulltime_freelancer'),
        then: schema => schema.required("Select an option"),
        otherwise: schema => schema.optional().nullable(true)
      })
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm,
        dirty
      }) => {
        const onCheckboxClick = async (fieldName, option, value) => {
          const currentValues = values[fieldName];
          let currentValuesArr = currentValues?.length ? currentValues.split('|') : [];
          if (value === true && currentValuesArr.indexOf(option) === -1) {
            currentValuesArr.push(option);
            if (errors[fieldName]) {
              setFieldError(fieldName, null);
            }
          } else if (value === false && currentValuesArr.indexOf(option) > -1) {
            currentValuesArr.splice(currentValuesArr.indexOf(option), 1)
          }
          const newValues = currentValuesArr.sort().join('|')
          await setFieldValue(fieldName, newValues);
          await setFieldTouched(fieldName, newValues !== initialValues[fieldName]);
        };

        const onRadioClick = async (name, value) => {
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null)
          }
        };
        return (
          <Form className="form">
            <div className="mb-2">
              <Label>{$.employment_status_settings_label}</Label>
              {EMPLOYMENT_STATUS_OPTIONS.map((option) => {
                const disabled =
                  (option.value === 'fulltime_freelancer' && values['employmentStatus']?.includes('parttime_freelancer')) ||
                  (option.value === 'parttime_freelancer' && values['employmentStatus']?.includes('fulltime_freelancer'));
                return (
                  <Checkbox
                    outlined
                    key={option.value}
                    id={option.value}
                    value={!!values['employmentStatus']?.includes(option.value)}
                    onChange={(v) => onCheckboxClick('employmentStatus', option.value, v)}
                    disabled={disabled || isUpdating}
                  >
                    <div
                      className={clsx("ml-1 my-1 font-sm font-lato", disabled ? "text-kasmir" : "text-darkest-navy")}
                    >
                      {option.label}
                    </div>
                  </Checkbox>
                );
              })}
              <FormErrorMessage error={errors.employmentStatus} />
            </div>
            {(!values['employmentStatus']?.includes('fulltime_freelancer')) &&
              <div className="mb-2">
                <Label>{$.fulltime_freelancing_label}</Label>
                <Sublabel>{$.fulltime_freelancing_sublabel}</Sublabel>
                {FULLTIME_FREELANCING_OPTIONS.map((option) =>
                  <Radio
                    outlined
                    key={option.value}
                    value={option.value}
                    name="openToFulltimeFreelance"
                    checked={values['openToFulltimeFreelance'] === option.value}
                    onChange={() => onRadioClick('openToFulltimeFreelance', option.value)}
                    disabled={isUpdating}
                  >
                    <div
                      className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
                  </Radio>
                )}
                <FormErrorMessage error={errors.openToFulltimeFreelance} />
              </div>
            }
            <div className="mb-2">
              <Label>{$.business_hours_label}</Label>
              <Sublabel>{$.business_hours_sublabel}</Sublabel>
              {BUSINESS_HOURS_OPTIONS.map((option) =>
                <Radio
                  outlined
                  key={option.value}
                  name='businessHoursAvailable'
                  value={option.value}
                  checked={values['businessHoursAvailable'] === option.value}
                  onChange={() => onRadioClick('businessHoursAvailable', option.value)}
                  disabled={isUpdating}
                >
                  <div
                    className="ml-1 my-1 font-sm text-darkest-navy font-lato">{option.label}</div>
                </Radio>
              )}
              <FormErrorMessage error={errors.businessHoursAvailable} />
            </div>
            {Object.values(touched).some(Boolean) && (
              <SettingsActionBar
                cancelDisabled={isUpdating}
                saveDisabled={isUpdating}
                onCancel={async () => {
                  for (let [key, value] of Object.entries(initialValues)) {
                    await setFieldValue(key, value);
                  }
                  await setTouched({});
                }}
                onSave={async () => {
                  setIsUpdating(true);
                  const errors = await validateForm();
                  if (!Object.keys(errors).length) {
                    await updateTalent(values);
                  }
                  setIsUpdating(false);
                }}
              />
            )}
          <PromptUpdater />
          </Form>
        )
      }}
    </Formik>);
}

export default EmploymentStatus;
