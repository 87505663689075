import React, {useEffect, useState} from "react";
import DebouncedInput from "./DebouncedInput";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import UilCircle from "@iconscout/react-unicons/icons/uil-circle";
import {B2B, D4} from "./Typography";
import { global as $ } from "strings";
import InputMask from "comigo-tech-react-input-mask";
import Select, {components} from "react-select";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import {AsyncPaginate} from "react-select-async-paginate";

/**
 * Renders a search input. It can be debounced if needed, to avoid many requests to the api.
 * @param {string} type - Type of the inner input, by default text.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {boolean} debounced - Flag to indicate if use a debounced input or not.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Search = React.forwardRef(
  ({ type = "text", error, debounced, ...props }, ref) => (
    <div className="relative">
      <div className="z-10 absolute left-0 top-0 bottom-0 flex items-center text-kasmir ml-2">
        <UilSearch size="16" />
      </div>
      {debounced ? (
        <DebouncedInput
          ref={ref}
          {...props}
          type={type}
          className={
            "w-full rounded text-sm font-normal h-10 w-66 pl-8 pr-3 text-kasmir bg-link-water focus:outline-none appearance-none border border-link-water focus:border-link focus:border-2 focus:bg-white " +
            (error ? "border-2 border-red" : "border-0")
          }
        />
      ) : (
        <input
          ref={ref}
          {...props}
          type={type}
          className={
            "w-full rounded text-sm font-normal h-10 w-66 pl-8 pr-3 text-kasmir bg-link-water focus:outline-none appearance-none border border-link-water focus:border-link focus:border-2 focus:bg-white " +
            (error ? "border-2 border-red" : "border-0")
          }
        />
      )}
    </div>
  )
);

/**
 * Renders an input.
 * @param {string} type - Type of the inner input, by default text.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Input = React.forwardRef(({ children, type = "text", error, fontSize, fontWeight, ...props }, ref) => (
  <div className="relative" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    <input
      ref={ref}
      {...props}
      onWheel={(e) => e.target.blur()}
      type={type}
      className={
        `w-full px-3 rounded-md h-10 w-60 flex items-center text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none ` +
        (fontSize || "") + ' ' +
        (fontWeight || "") + ' ' +
        (error
          ? "border-red-darker border"
          : props.disabled
          ? "border border-geyser bg-link-water"
          : "border border-geyser focus:border-kasmir")
      }
    />
    {children}
  </div>
));

/**
 * Renders an input.
 * @param {string} type - Type of the inner input, by default text.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {object} props - Extra props to attach to the inner input.
 */
const RateInput = React.forwardRef(({ children, type = "number", error, fontSize, ...props }, ref) => (
  <div className="relative">
    <span className="absolute left-3 top-2 bottom-2">$</span>
    <input
      ref={ref}
      {...props}
      onWheel={(e) => e.target.blur()}
      type={type}
      className={
        "w-full px-8 rounded h-10 w-60 flex items-center font-bold text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
        (`${fontSize} ` || "text-sm ") +
        (error
          ? "border-red-darker border-2"
          : props.disabled
          ? "border border-geyser bg-link-water"
          : "border border-geyser focus:border-link focus:border-2")
      }
    />
    {children}
  </div>
));


// renders an input that displays a https:// prefix
const LinkInput = React.forwardRef(({ type = "text", error, ...props }, ref) => (
  <div className="relative">
    <div className="z-10 absolute left-0 top-0 bottom-0 flex items-center text-kasmir bg-lightest-grey rounded-sm my-1 ml-1 p-1">
      <span className="text-sm">https://</span>
    </div>
    <input
      ref={ref}
      {...props}
      onWheel={(e) => e.target.blur()}
      type={type}
      className={
        "w-full px-3 rounded h-10 w-60 pl-16 pr-3 flex items-center font-bold text-sm sm:text-base text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
        (error
          ? "border-red-darker border-2"
          : props.disabled
          ? "border border-geyser bg-link-water"
          : "border border-geyser focus:border-link focus:border-2")
      }
    />
  </div>
));

const PhoneInput = React.forwardRef(({ value, onChange, type = "tel", error, ...props }, ref) => {
  return (
    <InputMask
      ref={ref}
      {...props}
      onWheel={(e) => e.target.blur()}
      type={type}
      className={
        "w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
        (error
          ? "border-red-darker border-2"
          : props.disabled
          ? "border border-geyser bg-link-water"
          : "border border-geyser focus:border-link focus:border-2")
      }
      mask="(999)-999-9999"
      placeholder="(###) ###-####"
      onChange={(e) => {
        onChange(e)
      }}
      value={value}
    />
  )
});

/**
 * Renders a textarea.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {boolean} small - Flag to indicate if the input is small.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Textarea = React.forwardRef(
  ({ error, small, unbolded, borderless, smallest, maxLength, ...props }, ref) => {
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
      if (props.value) {
        setCharCount(props.value.length);
      }
    }, [props.value]);

    const onChange = (e) => {
      setCharCount(e.target.value.length);
      if (props.onChange) {
        props.onChange(e);
      }
    };
    return (
      <div className="relative">
        <textarea
          ref={ref}
          {...props}
          onChange={onChange}
          className={
            "w-full rounded text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
            `${props.className || ''} ` +
            (error && !borderless
              ? "border-red-darker border-2 "
              : !borderless
                ? "border border-geyser focus:border-kasmir focus:border "
                : "") +
            (borderless ? "p-0 " : "px-2.5 py-2 ") +
            (smallest ? "h-8 " : small ? "h-12 " : "h-20 ") +
            (unbolded ? "font-normal" : "font-bold")
          }
        />
        {maxLength && (
          <div className="absolute right-3 bottom-3 text-xs text-kasmir">
            {charCount}/{maxLength}
          </div>
        )}
      </div>
    );
  }
  )
;

/**
 * Renders label to be used with a form field.
 * @param {component} children - The children to render, in most cases a clear button.
 * @param {string} className - Classes to append to the label default classes.
 */
const Label = ({children, className = ""}) => (
  <label
    className={
      "text-kasmir font-normal text-xs mb-1 flex items-center " + className
    }
  >
    {children}
  </label>
);

/**
 * Renders sublabel to be used with a form field.
 * @param {component} children - The children to render, in most cases a clear button.
 * @param {string} className - Classes to append to the label default classes.
 */
const Sublabel = ({ children, className = "" }) => (
  <div
    className={
      "text-kasmir font-normal text-sm mb-1 flex items-center " + className
    }
  >
    {children}
  </div>
);

/**
 * Renders clear button to be used inside form fields labels. Used to clear filters.
 * @param {string} text - Text to show in the button, it has a default value.
 * @param {callback} onClick - Callback to trigger on click.
 */
const Clear = ({ text = $.clear_button, onClick }) => (
  <div className="flex-1 flex justify-end">
    <button
      className="focus:outline-none appearance-none inline float-right font-xs text-link font-bold"
      onClick={onClick}
    >
      {text}
    </button>
  </div>
);

/**
 * Renders a form field error.
 * @param {component} children - The children to render, the error message.
 */
const Error = ({ children }) => <D4>{children}</D4>;

/**
 * Renders a checkbox field.
 * @param {component} children - The children to render, the text of the checkbox.
 * @param {boolean} value - Value of the checkbox.
 * @param {callback} onChange - Callback to catch value changes.
 * @param {object} props - Extra props to attach to the inner checkbox input.
 */
const Checkbox = ({ children, value, onChange, disabled, outlined, ...props }) => (
  <label className="flex items-center font-lato text-midnight font-normal text-sm">
    <input
      type="checkbox"
      className="hidden"
      checked={value}
      onChange={(e) => {
        if (disabled) return;
        onChange(e.target.checked)
      }}
      {...props}
    />
    <div
      className={
        "w-4 h-4 mr-1.5 text-xs rounded cursor-pointer text-white flex items-center justify-center flex-shrink-0 " +
        (outlined ? "border border-black " : "border-none ") +
        (value ? outlined ? "border-electric-indigo bg-electric-indigo " : "bg-link " : outlined ? "bg-none " : "bg-geyser ") +
        (disabled ? "cursor-not-allowed" : "cursor-pointer")
      }
    >
      {value && <UilCheck />}
    </div>
    {children}
  </label>
);

/**
 * Renders a radio button field.
 * @param {component} children - The children to render, the text of the radio button.
 * @param {boolean} value - Value of the radio button.
 * @param {callback} onChange - Callback to catch value changes.
 * @param {object} props - Extra props to attach to the inner radio button input.
 */
const Radio = ({ children, name, value, checked, onChange, disabled, outlined,...props }) => (
  <label className="flex items-center font-helvetica text-kasmir font-normal text-sm">
    <input
      type="radio"
      className="hidden"
      name={name}
      value={value}
      checked={checked}
      onChange={(e) => {
        if (disabled) return;
        onChange()
      }}
      {...props}
    />
    <div
      className={
        "w-4 h-4 mr-1 text-xs text-bold rounded-full cursor-pointer text-white flex items-center justify-center flex-shrink-0 " +
        (outlined ? "border border-black " : "border-none ") +
        (checked ? outlined ? "bg-electric-indigo border-electric-indigo " : "bg-link " : outlined ? "bg-none " : "bg-geyser ") +
        (disabled ? "cursor-not-allowed" : "cursor-pointer")
      }
    >
      {checked && <UilCircle />}
    </div>
    {children}
  </label>
);


const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <UilAngleDown size="20" />
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <UilTimes size="20" />
  </components.ClearIndicator>
);

const MenuList = ({ children, selectProps, ...rest }) => {
  return (
    <components.MenuList {...rest}>
      {children}
      {selectProps.footerText && (
        <div className="border-t border-link-water py-3 px-4 sticky bg-white bottom-0">
          <B2B className="cursor-pointer" onClick={selectProps.footerOnClick}>
            {selectProps.footerText}
          </B2B>
        </div>
      )}
    </components.MenuList>
  );
};

const baseStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#dce9f6"
      : state.isFocused
      ? "#f4f6f9"
      : "#fff",
    fontWeight: state.isSelected ? "500" : "400",
    fontSize: "14px",
    fontFamily: "Lato",
    borderRadius: "2px",
    color: state.isDisabled ? "#a8b7c7" : state.isSelected ? "#16325c" : "#54698d",
    "&:hover": {
      backgroundColor: state.isSelected ? "#dce9f6" : "#f4f6f9",
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    color: "#16325c",
    backgroundColor: "#f4f6f9",
    borderRadius: "6px",
    margin: "0",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontWeight: "600",
    fontSize: "14px",
    color: "#16325c",
    padding: "0",
    paddingLeft: "0",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#16325c",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    "&:hover": {
      color: "#16325c",
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    "&:hover": {
      color: "#16325c",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0",
    border: "solid 1px #d8dde6",
    marginTop: "5px",
    zIndex: "40",
    padding: "4px",
    borderRadius: "6px",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: "400",
    color: "#54698d",
    fontSize: "14px",
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%",
    fontWeight: "700",
    color: "#16325c",
    columnGap: "6px",
    rowGap: "4px",
    paddingTop: "4px",
    paddingBottom: "4px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    color: "#16325c",
    fontWeight: "700",
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontFamily: "Lato",
    fontSize: "14px",
    margin: "0",
    padding: "0",
    color: "#16325c",
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
    fontWeight: "400",
    color: "#54698d",
    fontSize: "14px",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    border: state.selectProps.error
      ? "solid 1px #a10000"
      : state.isFocused
      ? "solid 1px #54698d"
      : state.isSelected
      ? "solid 1px #54698d"
      : state.selectProps.secondary
      ? "0"
      : "solid 1px #d8dde6",
    "&:hover": {
      border: state.isSelected
        ? "solid 1px #54698d"
        : state.isFocused
        ? "solid 1px #54698d"
        : "solid 1px #d8dde6",
    },
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: "#54698d",
  }),
  loadingMessage: (provided) => ({
    ...provided,
    color: "#54698d",
    fontSize: "14px",
    fontFamily: "Lato",
  }),
};

const Dropdown = ({
  fontSize,
  error,
  value,
  options,
  async,
  customStyles = {},
  ...props
}) => {
  const BaseSelect = async ? AsyncPaginate : Select;
  return (
  <BaseSelect
    debounceTimeout={250}
    cacheOptions={async}
    defaultOptions={async}
    menuPortalTarget={document.body}
    styles={{ ...baseStyles, ...customStyles }}
    className={
      "w-full rounded h-10 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font focus:outline-none appearance-none " +
      (`${fontSize} ` || "text-sm ") +
      (
        error
        ? "border-red-darker border"
        : props.disabled
        ? "cursor-not-allowed border border-geyser bg-link-water"
        : "border border-geyser focus:border-kasmir")
    }
    {...props}
    options={options}
    value={props.isMulti ? value : options ? options.find(o => o.value === value) : value?.value}
    components={{
      ...components,
      ...{
        DropdownIndicator,
        ClearIndicator,
        MenuList,
        ...props.components,
      },
    }}
    isOptionDisabled={(option) => option.disabled}
  />
  );
};

export { Input, PhoneInput, LinkInput, RateInput, Textarea, Checkbox, Radio, Search, Label, Sublabel, Error, Clear, Dropdown };
