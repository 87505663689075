import React, {useEffect, useState} from "react";
import Terms from "pages/info/Terms";
import Privacy from "pages/info/Privacy";
import Onboard from "pages/talent/onboard/index";
import ErrorPage from "pages/Error";
import AdminPage from "pages/admin/Page";
import TalentPage from "pages/talent/Page";
import Login from "pages/talent/Login";
import TalentRegister from "pages/talent/Register";
import TalentRegisterPassword from "pages/talent/RegisterPassword";
import TalentResetPassword from "./pages/talent/ResetPassword";
import TalentResetPasswordSuccess from "pages/talent/ResetPasswordSuccess";
import TalentResetPasswordConfirm from "pages/talent/ResetPasswordConfirm";
import ScrollToTop from "components/ScrollToTop";
import {PageCacheProvider} from "contexts/pageCacheContext";
import {
  getAccessToken,
  getAdminAccessToken,
  getUrlSuffix,
} from "utils/localStorageService";
import history from "utils/history";
import {configureLoggedSetter, getFlags,} from "utils/api";
import {
  configureLoggedSetter as configureAdminLoggedSetter
} from "utils/adminApi";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {positions, Provider as AlertProvider, transitions} from "react-alert";
import AlertTemplate from "components/AlertTemplate";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import DBConfig from "utils/DBConfig";
import {initDB} from "react-indexed-db";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Helmet} from "react-helmet";
import NotFoundPage from "./pages/talent/NotFound";

initDB(DBConfig);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 0,
  offset: ".5rem",
  transition: transitions.FADE,
  containerStyle: { marginTop: "4rem", zIndex: "100" },
};

const App = () => {
  const [logged, setLogged] = useState(!!getAccessToken());
  const [adminLogged, setAdminLogged] = useState(!!getAdminAccessToken());
  const [suffix, setSuffix] = useState(getUrlSuffix());
  const [flags, setFlags] = useState({});

  useEffect(() => {
    getFlags().then(setFlags);
    setLogged(!!getAccessToken());
    setAdminLogged(!!getAdminAccessToken());
    setSuffix(getUrlSuffix());
  }, []);

  configureLoggedSetter(setLogged);
  configureAdminLoggedSetter(setAdminLogged);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Helmet>
        <meta name="robots" content="noindex, noarchive, nofollow" />
      </Helmet>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <Router history={history}>
            <ScrollToTop />
            {/* don't display anything until flags are loaded, it causes routing bugs */}
            {Object.keys(flags).length === 0 ? (<div />) : (
            <Switch>
              <Route exact path="/404">
                <NotFoundPage />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/login">
                {!!adminLogged ? (
                  <Redirect to="/admin" />
                ) : (logged && suffix && flags.talent_tech_enabled) ? (
                  <Switch>
                    <Redirect to={`/talent/${suffix}`} />
                  </Switch>
                ) : (
                  <Login {...{ setLogged, setAdminLogged }} />
                )}
              </Route>
              <Route exact path="/talent/onboard">
                {!logged && flags.talent_tech_enabled ? (
                  <Switch>
                    <Redirect to="/talent/register" />
                  </Switch>
                ) : (
                  <Onboard flags={flags} />
                )}
              </Route>
              <Route path="/talent">
                <Route exact path="/talent/login">
                  <Redirect to="/login" />
                </Route>
                {logged && flags.talent_tech_enabled ? (
                  <Switch>
                    <Route exact path="/talent/register-password">
                      <Redirect to="/talent" />
                    </Route>
                    <Route exact path="/talent/register">
                      <Redirect to="/talent" />
                    </Route>
                    <Route exact path="/talent/reset-password">
                      <Redirect to="/talent" />
                    </Route>
                    <Route exact path="/talent/reset-password-success">
                      <Redirect to="/talent" />
                    </Route>
                    <Route path="/talent/reset-password-confirm">
                      <Redirect to="/talent" />
                    </Route>
                    <Route path="/talent/null">
                      <Redirect to="/404"/>
                    </Route>
                    <Route path="/talent/:id">
                      <TalentPage />
                    </Route>
                    <Route path="/talent">
                      <Redirect to={`/talent/${suffix}`}/>
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/talent/register-password">
                      <TalentRegisterPassword {...{ setLogged }} />
                    </Route>
                    <Route exact path="/talent/register">
                      <TalentRegister {...{ setLogged }} />
                    </Route>
                    <Route exact path="/talent/reset-password">
                      <TalentResetPassword {...{ setLogged }} />
                    </Route>
                    <Route exact path="/talent/reset-password-success">
                      <TalentResetPasswordSuccess {...{ setLogged }} />
                    </Route>
                    <Route path="/talent/reset-password-confirm">
                      <TalentResetPasswordConfirm {...{ setLogged }} />
                    </Route>
                    <Route path="/talent/:id">
                      <TalentPage />
                    </Route>
                    <Route path="/talent">
                      <Redirect to="/login" />
                    </Route>
                  </Switch>
                )}
              </Route>
              <Route path="/admin">
                <Route exact path="/admin/login">
                  <Redirect to="/login" />
                </Route>
                {adminLogged ? (
                  <Switch>
                    <Route path="/admin">
                      <PageCacheProvider>
                        {(loaded) => (
                          <>{loaded && <AdminPage {...{ setAdminLogged }} />}</>
                        )}
                      </PageCacheProvider>
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/admin">
                      <Redirect to="/login" />
                    </Route>
                  </Switch>
                )}
              </Route>
              <Route path="/">
                {adminLogged ? (
                  <Redirect to="/admin" />
                ) : (logged && flags.talent_tech_enabled) ? (
                  (suffix === "null" || !suffix) ? (
                    <Redirect to="/talent" />
                  ) : (
                  <Switch>
                    <Redirect to={`/talent/${suffix}`} />
                  </Switch>
                  )
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>)}
          </Router>
        </AlertProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
